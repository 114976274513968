import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'

import useUtils from 'hooks/useUtils'
import { getUserEmail } from 'helpers/getUser'
import { Loading, MessageHandler } from 'components/core'
import { ReactComponent as IconBack } from 'assets/svg/icon-back.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: 10,
    padding: 24,
  },
  title: {
    fontSize: '1.75rem',
    fontWeight: 'bold',
    fontFamily: 'var(--font-poppins)',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    color: theme.palette.text.primary,
  },
  subTitle: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    fontFamily: 'var(--font-poppins)',
  },
  grid: {
    textAlign: 'center',
  },
  borderBox: {
    marginLeft: -1,
    width: 1,
    height: 75,
    backgroundColor: '#D6D6D6',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: -1,
      width: '40vw',
      height: 1,
    },
  },
  premium: {
    fontSize: '2.25rem',
    color: theme.palette.primary.main,
  },
  currencyDescription: {
    fontSize: '0.875rem',
    letterSpacing: 1.2,
    display: 'flex',
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  deadlineGrid: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 720,
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'start',
      paddingLeft: '40px !important',
      paddingRight: '60px !important',
    },
  },
  deadlineText: {
    fontSize: '0.875rem',
  },
  emailGrid: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 720,
    },
    marginTop: theme.spacing(1),
  },
  emailText: {
    fontSize: '1rem',
  },
  email: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  downloadText: {
    fontSize: '0.875rem',
  },
  buttons: {
    marginTop: theme.spacing(4),
  },
  iconDownload: {
    width: 20,
    height: 20,
    marginRight: 10,
    marginTop: -10,
    marginBottom: -10,
  },
  iconBack: {
    width: 20,
    height: 36,
    marginRight: 10,
    marginTop: -10,
    marginBottom: -10,
    fill: '#FFFFFF',
  },
}))

const PolicyFeedback = ({
  isLoading,
  policyValue,
  policyNumber,
  duration,
  automaticRenewalDate,
  errors,
}) => {
  const classes = useStyles()
  const { formatCurrency } = useUtils()

  const handleBackButtonClick = () => {
    window.location.assign(window.ENV.GARANTIA_DIGITAL)
  }

  return (
    <Loading isLoading={isLoading}>
      <Box className={classes.root}>
        <Typography component="h1" className={classes.title}>
          Sua apólice foi emitida com sucesso!
        </Typography>

        <Paper className={classes.paper}>
          <Grid container direction="row" alignItems="center" justify="center" spacing={5} className={classes.grid}>
            <Grid item xs={12}>
              <Typography component="h2" className={classes.subTitle}>
                Apólice nº {policyNumber}
              </Typography>
            </Grid>

            <Grid item md={5} xs={12}>
              <Grid container direction="column" alignItems="center">
                <Grid item xs={12}>
                  <Typography component="span" className={classes.premium}>
                    {formatCurrency(policyValue)}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography component="span" className={classes.currencyDescription}>
                    À VISTA NO BOLETO BANCÁRIO
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Box className={classes.borderBox} />

            <Grid item md={7} xs={12} className={classes.deadlineGrid}>
              <Typography component="span" className={classes.deadlineText}>
                O valor é único e válido por <b>{duration} anos</b>.
                Lembramos que a apólice será <b>renovada automaticamente</b> no seu
                fim de vigência com a antecedência de 60 dias no dia <b>{automaticRenewalDate}</b>.
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.emailGrid}>
              <Typography component="span" className={classes.emailText}>
                Em breve, você receberá no seu e-mail <span className={classes.email}>{getUserEmail()}</span> uma cópia da apólice,
                do boleto e das certidões que deverão ser juntados no processo judicial.
              </Typography>
            </Grid>
          </Grid>

          <Grid pb={2} container justify="center" spacing={2} className={classes.buttons}>
            <Grid item sm={6} xs={12}>
              <Button
                color="primary"
                variant="contained"
                title="Voltar para página inicial"
                fullWidth
                onClick={handleBackButtonClick}
                startIcon={<IconBack className={classes.iconBack} />}
              >
                Voltar para página inicial
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <MessageHandler messages={errors} type="error" />
      </Box>
    </Loading>
  )
}

PolicyFeedback.propTypes = {
  isLoading: PropTypes.bool,
  policyValue: PropTypes.number,
  policyNumber: PropTypes.string,
  duration: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  automaticRenewalDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  errors: PropTypes.array,
}

PolicyFeedback.defaultProps = {
  isLoading: false,
  policyValue: 0,
  policyNumber: null,
  duration: '',
  automaticRenewalDate: '',
  errors: [],
}

export default PolicyFeedback

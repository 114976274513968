import React from 'react'

import { AuthConsumer } from 'utils/auth/authProvider'

const SilentRenewCallback = () => (
  <AuthConsumer>
    {({ signinSilentCallback }) => {
      signinSilentCallback()
      return <span>...</span>
    }}
  </AuthConsumer>
)

export default SilentRenewCallback

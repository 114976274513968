import React from 'react'

import Layout from 'components/core/Layout'
import SearchProcessContainer from 'containers/Process/Search'

const HomeContainer = () => (
  <Layout>
    <SearchProcessContainer />
  </Layout>
)

export default HomeContainer

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, makeStyles } from '@material-ui/core'
import { ResourceActions } from 'store/ducks/resource'
import { ProcessActions } from 'store/ducks/process'
import { AddressActions } from 'store/ducks/address'
import { PeopleActions } from 'store/ducks/people'
import SearchProcess from 'components/presentation/Process/Search'
import useProcess from 'hooks/useProcess'
import { history } from 'store'
import urls from 'utils/constants/urls'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 920,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

const SearchProcessContainer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { location } = history
  const { dontReset } = location

  const handleRedirectAddress = () => {
    history.push({
      pathname: urls.ROUTES.registerAddress.path,
      // state: processNumber,
    })
  }

  const { processData, search } = useProcess()

  const handleIdentify = (value) => {
    dispatch(ProcessActions.setIdentifyUser(value))
  }

  const { isLoading: resourceIsLoading, data: resourceData } = useSelector(
    (state) => state.resource
  )

  const {
    brokerSearch: { isLoading: brokerIsLoading, data: brokerData },
    brokerAppointment: {
      isLoading: verifyAppointmentIsLoading,
      allowBrokerSearch,
      data: appointmentData,
    },
  } = useSelector((state) => state.process)

  useEffect(() => {
    dispatch(ResourceActions.getResource())
  }, [dispatch])

  useEffect(() => {
    if (!dontReset) {
      dispatch(AddressActions.resetAddress())
      dispatch(PeopleActions.resetPeople())
      dispatch(ProcessActions.resetProcess())
      dispatch(ProcessActions.resetErrors())
    }
  }, [dontReset, dispatch])

  const onSearchProcessNumber = (number) => {
    dispatch(ProcessActions.searchProcess(number))
  }

  const handleSearchBroker = (filter) => {
    const payload = {
      brokerName: filter,
      pageNumber: 1,
      pageSize: 15,
      productKey: 'garantiaRecursal',
    }
    dispatch(ProcessActions.searchBroker(payload))
  }

  const handleVerifyBrokerAppointment = (defendantDocument) => {
    dispatch(ProcessActions.verifyBrokerAppointment(defendantDocument))
  }

  const handleResetBrokerAppointment = () => {
    dispatch(ProcessActions.resetBrokerAppointment())
  }

  const saveProcessData = (values) => {
    dispatch(ProcessActions.setProcess(values))
    localStorage.removeItem('complainant')
    handleRedirectAddress()
  }

  return (
    <Box className={classes.root}>
      <SearchProcess
        onSearchProcessNumber={onSearchProcessNumber}
        handleIdentify={handleIdentify}
        isLoading={search.isLoading}
        handleSearchBroker={handleSearchBroker}
        brokerIsLoading={brokerIsLoading}
        brokerOptions={brokerData}
        handleVerifyBrokerAppointment={handleVerifyBrokerAppointment}
        verifyAppointmentIsLoading={verifyAppointmentIsLoading}
        allowBrokerSearch={allowBrokerSearch}
        appointmentData={appointmentData}
        handleResetBrokerAppointment={handleResetBrokerAppointment}
        resourceIsLoading={resourceIsLoading}
        resourceData={resourceData}
        saveProcessData={saveProcessData}
        searchErrors={search.errors}
        processData={processData}
        searchProcessResponseData={search.data}
      />
    </Box>
  )
}

export default SearchProcessContainer

import { useCallback } from 'react'
import { isEmpty } from 'lodash'
import moment from 'moment'

/* Defining the location 'pt-br' required when using the moment. */
import 'moment/locale/pt-br'

moment.locale('pt-br')

const useUtils = () => {
  const getOnlyNumber = useCallback((value = '') => value.replace(/\D/g, ''), [])

  const isBlank = useCallback((value) => {
    let data = ''

    if (typeof value === 'boolean') {
      data = `${value}`
    } else {
      data = `${value || ''}`
    }

    return isEmpty(data) || data.trim().length === 0
  }, [])

  const formatCEP = useCallback((value) => !isBlank(value) && value.replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2-$3'), [isBlank])

  const formatCPF = useCallback((value) => !isBlank(value) && value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'), [isBlank])

  const formatCNPJ = useCallback((value) => !isBlank(value) && value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5'), [isBlank])

  const formatCPFOrCNPJ = useCallback((value) => !isBlank(value) && getOnlyNumber(value).length === 11 ? formatCPF(value) : formatCNPJ(value), [isBlank, getOnlyNumber, formatCPF, formatCNPJ])

  const formatSUSEP = useCallback((value) => !isBlank(value) && value.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4'), [isBlank])

  const date = useCallback((value) => (value ? moment(value) : moment()), [])

  const formatHours = useCallback((value) => value && moment(value).format('LT'), [])

  const formatDate = useCallback((value) => value && moment(value).format('DD/MM/YYYY'), [])

  const formatDateApi = useCallback((value) => value && moment(value).format('YYYY/MM/DD'), [])

  const formatDateNews = useCallback((value) => value && `${moment(value).format('DD')} de ${moment(value).format('MMMM YYYY')}`, [])

  const formatPhoneOrCellphone = useCallback((value) => {
    if (!isBlank(value)) {
      let number = getOnlyNumber(value)
      number = number.replace(/^(\d{2})(\d)/g, '($1) $2')
      number = number.replace(/(\d)(\d{4})$/, '$1-$2')
      return number
    }

    return ''
  }, [isBlank, getOnlyNumber])

  const formatCurrency = (value) => {
    const number = !isBlank(value) ? parseFloat(value) : parseFloat(0)

    return number.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    })
  }

  return {
    getOnlyNumber,
    isBlank,
    formatCEP,
    formatCPF,
    formatCNPJ,
    formatCPFOrCNPJ,
    formatSUSEP,
    date,
    formatHours,
    formatDate,
    formatDateApi,
    formatDateNews,
    formatPhoneOrCellphone,
    formatCurrency,
  }
}

export default useUtils

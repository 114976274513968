import React, { useEffect, useState, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { Box, makeStyles } from '@material-ui/core'
import ReactGA from 'react-ga'

import { AddressActions } from 'store/ducks/address'
import { PeopleActions } from 'store/ducks/people'
import { ProcessActions } from 'store/ducks/process'
import { getComplainant } from 'helpers/localStorage'
import useProcess from 'hooks/useProcess'
import getProcessObject from 'helpers/getProcessObject'
import useUtils from 'hooks/useUtils'
import Loading from 'components/core/Loading'
import { history } from 'store'
import urls from 'utils/constants/urls'
import Layout from 'components/core/Layout'
import RegisterAddress from 'components/presentation/RegisterAddress'
import { RESTRICTED_LIST_CODE } from 'utils/constants/errorCodes'
import useResource from 'hooks/useResource'

const ValidationFeedback = React.lazy(() =>
  import('containers/Process/Register/ValidationFeedback')
)
const UnauthorizedFeedback = React.lazy(() =>
  import('containers/Process/Register/UnauthorizedFeedback')
)

const useStyles = makeStyles((theme) => ({
  root: {
    width: 920,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

const RegisterAddressContainer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { getOnlyNumber } = useUtils()

  const [approved, setApproved] = useState(false)

  const {
    validation: { denied, status },
  } = useSelector((state) => state.process)

  const { isSuccess, errors: saveErrors } = useSelector(
    ({ process }) => process.save
  )

  const {
    isLoading: complainantDataIsLoading,
    isSuccess: complainantDataIsSuccess,
    data: complainantData,
    errors: complainantDataErrors,
  } = useSelector(({ people }) => people.complainant)

  const {
    isLoading: complainantAddressIsLoading,
    isSuccess: complainantAddressIsSuccess,
    data: complainantAddressData,
    errors: complainantAddressErrors,
    update: addressUpdate,
  } = useSelector(({ address }) => address.complainant)

  const { data: resourceData } = useSelector((store) => store.resource)
  const { getRiskObjectText } = useResource()

  const {
    processNumber,
    isLoading: processIsLoading,
    processData,
  } = useProcess()

  const { complainantDocument } = getComplainant()

  const handleRestartJourney = () => {
    dispatch(AddressActions.resetAddress())
    dispatch(PeopleActions.resetPeople())
    dispatch(ProcessActions.resetProcess())
    dispatch(ProcessActions.resetErrors())

    history.push(urls.ROUTES.app.path)
  }

  useEffect(() => {
    if (isEmpty(processNumber)) {
      handleRestartJourney()
    }
  })

  useEffect(() => {
    if (complainantDocument) {
      dispatch(PeopleActions.getComplainant(getOnlyNumber(complainantDocument)))
    }
  }, [dispatch, getOnlyNumber, complainantDocument])

  useEffect(() => {
    if (addressUpdate?.isSuccess) {
      const riskObjectText = getRiskObjectText()

      dispatch(
        ProcessActions.saveProcess(
          getProcessObject(
            processNumber,
            processData,
            complainantData,
            riskObjectText
          )
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressUpdate?.isSuccess])

  const getComplainantAddress = (zipCode) => {
    dispatch(AddressActions.getComplainantAddress(zipCode))
  }

  const handleGetComplainant = (document) => {
    dispatch(PeopleActions.getComplainant(getOnlyNumber(document)))
  }

  const resetComplainant = () => {
    dispatch(PeopleActions.resetComplainant())
  }

  const updateComplainantAddress = (values) => {
    const payload = { ...values.complainant }

    dispatch(
      AddressActions.updateComplainantAddress(
        payload,
        getOnlyNumber(values?.complainant?.document)
      )
    )
  }

  useEffect(() => {
    if (!denied && isSuccess) {
      setApproved(true)
    }
  }, [denied, isSuccess, processNumber])

  const handleIgnoreApproval = () => {
    setApproved(false)
    handleRestartJourney()
  }

  useEffect(() => {
    if (denied && !!window.ENV.GA_CONFIG) {
      if (status === 'UNAUTHORIZED') ReactGA.pageview('/cotacao-nao-autorizada')
      else if (status === 'DENIED') ReactGA.pageview('/cotacao-negada')
    }
  }, [denied])

  const addressComponent = (
    <Box className={classes.root}>
      <RegisterAddress
        complainantDataIsLoading={complainantDataIsLoading}
        complainantData={complainantData}
        complainantName={complainantData?.name}
        complainantDataIsSuccess={complainantDataIsSuccess}
        complainantDataErrors={complainantDataErrors}
        getComplainant={handleGetComplainant}
        getComplainantAddress={getComplainantAddress}
        resetComplainant={resetComplainant}
        complainantAddressIsLoading={complainantAddressIsLoading}
        complainantAddressIsSuccess={complainantAddressIsSuccess}
        complainantAddressErrors={complainantAddressErrors}
        complainantAddressData={complainantAddressData}
        updateComplainantAddress={updateComplainantAddress}
        defendantDocument={getOnlyNumber(processData?.defendant?.document)}
        isLoading={complainantAddressIsLoading}
        processIsLoading={processIsLoading}
        processNumber={processNumber}
        saveErrors={saveErrors}
        approved={approved}
        ignoreApproval={handleIgnoreApproval}
      />
    </Box>
  )

  const renderContent = () => {
    if (saveErrors?.find((err) => err.Code === RESTRICTED_LIST_CODE)) {
      return <ValidationFeedback />
    }

    if (denied || complainantDataErrors?.length > 0) {
      if (!status) return <ValidationFeedback />
      return <UnauthorizedFeedback />
    }

    return addressComponent
  }

  return (
    <Layout>
      <Suspense fallback={<Loading isLoading />}>{renderContent()}</Suspense>
    </Layout>
  )
}

export default RegisterAddressContainer

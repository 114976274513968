import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import checked from 'assets/svg/checked.svg'

const complete = css`
  content: url(${checked});
  background-color: var(--primary);
`

const active = css`
  color: var(--primary);
  background-color: var(--white);
`

const incomplete = css`
  background-color: #959BA5;
  border-color: #959BA5;
`

export const Step = styled.div`
  display: inline-block;
  padding-top: 36px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
  color: ${({ isCompleted, isActive }) => (isCompleted || isActive ? 'var(--primary)' : '#959BA5')};
  position: relative;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 165px;

  ${media.lessThan('small')`
    font-size: 0px;
  `}

  
  &::before {
    position: absolute;
    top: 2px;
    left: calc(50% - 12px);
    z-index: 1;
    width: 24px;
    height: 24px;
    color: white;
    border: 1px solid var(--primary);
    border-radius: 17px;
    font-size: 12px;
    line-height: 23px;
    font-weight: normal;
    
    content: '${({ stepNumber }) => stepNumber}';

    ${({ isCompleted, isActive }) => (isCompleted ? complete : (isActive ? active : incomplete))}
  }
`

export const Bar = styled.div`
  width: 105px;
  height: 1px;
  background: #bebebe;
  margin: 0px 30px;
`

export const Bars = styled.div`
  width: 495px;
  position: relative;
  top: 35px;
  margin: 0 auto;
  display: flex;
  flex-flow: row;

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: center;
  padding 20px 0px 60px;

  ${media.lessThan('medium')`
    padding: 1.25rem;
    margin-left: 0;
    width: 100%;
  `}
`

import React from 'react'
import { authService } from './authService'

const AuthContext = React.createContext({
  signinRedirectCallback: () => ({}),
  signinSilentCallback: () => ({}),
  signoutRedirectCallback: () => ({}),
  getUser: () => ({}),
  isAuthenticated: () => ({}),
})

export const AuthConsumer = AuthContext.Consumer

export const AuthProvider = ({ children }) => (
  <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>
)

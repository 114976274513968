import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Checkbox,
  Fade,
  Grid,
  Link,
  makeStyles,
  Paper,
  Tooltip as TooltipMui,
  Typography,
} from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'

import { history } from 'store'
import urls from 'utils/constants/urls'
import useUtils from 'hooks/useUtils'
import { Loading, MessageHandler } from 'components/core'
import proposalValue from 'assets/svg/proposal.svg'
import checkbox from 'assets/svg/checkbox.svg'
import ProposalTerms from 'assets/documents/Termos_e_Condicoes_Gerais_Emissao_de_Apolice.pdf'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 320,
    minHeight: 605,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    marginBottom: 10,
    padding: 24,

    boxShadow: '0px 0px 12px #F26522',
    position: 'sticky',
    top: 10,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 0,
    },
  },
  mainTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'var(--font-poppins)',
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
  image: {
    content: `url(${proposalValue})`,
    width: 80,
    height: 80,
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      width: 60,
      height: 60,
      marginTop: theme.spacing(1),
    },
  },
  premium: {
    fontSize: '2rem',
    color: theme.palette.primary.main,
  },
  currencyDescription: {
    fontSize: '0.875rem',
    letterSpacing: 1.2,
    display: 'flex',
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  valuesGrid: {
    width: '100%',
  },
  valuesPaper: {
    backgroundColor: theme.palette.background.gray,
    marginTop: theme.spacing(4),
    padding: 24,
  },
  valueGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  valueDescription: {
    fontSize: '0.625rem',
  },
  value: {
    fontSize: '0.875rem',
    fontWeight: 600,
    letterSpacing: 0.8,
  },
  valueYears: {
    fontSize: '0.75rem',
    letterSpacing: 0.88,
    color: theme.palette.primary.main,
  },
  borderRight: {
    borderRight: '1px solid #D6D6D6',
  },
  termsGrid: {
    marginTop: theme.spacing(3),
  },
  checkboxHighlighted: {
    display: 'block',
    borderRadius: 3,
    width: 18,
    height: 18,
    margin: 3,
    content: `url(${checkbox})`,
    boxShadow: '0px 0px 6px #F26522DE',
  },
  terms: {
    fontSize: '0.875rem',
    fontWight: 600,
  },
  link: {
    textDecoration: 'underline',
    marginTop: -3.5,
  },
  buttons: {
    marginTop: theme.spacing(1),
  },
  buttonHighlighted: {
    boxShadow: '0px 0px 16px #F26522',
  },
  backButtonGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  backButton: {
    fontSize: '0.875rem',
    '&:hover': {
      textDecoration: 'none',
    }
  },
  backButtonDisabled: {
    color: theme.palette.text.lightGray,
    cursor: 'default',
  },
  errorIcon: {
    fill: '#C3284C',
    width: 16,
    height: 16,
    marginLeft: -16,
    marginTop: 4,
  },
  tooltip: {
    width: 268,
    backgroundColor: '#626262',
    fontSize: 12,
    fontFamily: 'Open Sans',
    padding: 14,
    fontWeight: 400,
    textAlign: 'center',
  },
  tooltipArrow: {
    color: '#626262',
    marginRight: 200,
    left: '26px !important',
    [theme.breakpoints.down('xs')]: {
      left: '27px !important',
    },
  },
}))

const ProposalSection = ({
  proposal,
  createPolicy,
  policyIsLoading,
  policyErrors,
}) => {
  const classes = useStyles()
  const { formatCurrency, formatDate } = useUtils()

  const [isTermsAccepted, setIsTermsAccepted] = useState(false)
  const [openTermsTooltip, setOpenTermsTooltip] = useState(false)

  const handlePolicyIssue = () => {
    if (isTermsAccepted) {
      createPolicy()
    } else {
      setOpenTermsTooltip(true)
    }
  }

  const handleBackButtonClick = () => {
    !isTermsAccepted && history.push(urls.ROUTES.simulator.path)
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography component="h1" className={classes.mainTitle}>
              Valor do seguro
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box className={classes.image} />
          </Grid>

          <Grid item xs={12}>
            <Typography component="span" className={classes.premium}>
              {formatCurrency(proposal?.premium)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography component="span" className={classes.currencyDescription}>
              À VISTA NO BOLETO BANCÁRIO
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.valuesGrid}>
            <Paper elevation={0} className={classes.valuesPaper}>
              <Grid container>
                <Grid item xs={12} className={[classes.valueGrid, classes.marginBottom]}>
                  <Typography className={classes.valueDescription}>
                    VIGÊNCIA DA APÓLICE
                  </Typography>

                  <Typography className={classes.value}>
                    Até {formatDate(proposal.policyPeriodEnd)}
                  </Typography>

                  <Typography className={classes.valueYears}>
                    {proposal.duration} ANOS
                  </Typography>
                </Grid>
                <Grid item xs={6} className={[classes.valueGrid, classes.borderRight]}>
                  <Typography className={classes.valueDescription}>
                    VALOR SEGURADO
                  </Typography>

                  <Typography className={classes.value}>
                    {formatCurrency(proposal.contractValue)}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.valueGrid}>
                  <Typography className={classes.valueDescription}>
                    VALOR + AGRAVO
                  </Typography>

                  <Typography className={classes.value}>
                    {formatCurrency(proposal.insuredAmount)}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <TooltipMui
          title="Você deve aceitar os Termos e Condições da proposta para prosseguir."
          placement="top"
          arrow
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 300 }}
          classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
          onClose={() => { }}
          open={openTermsTooltip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="center"
            className={classes.termsGrid}
          >
            {openTermsTooltip && (
              <Grid item xs="auto">
                <ErrorIcon className={classes.errorIcon} />
              </Grid>
            )}
            <Grid item xs="auto">
              <Checkbox
                icon={<span className={classes.checkboxHighlighted} />}
                color="primary"
                onChange={() => {
                  setIsTermsAccepted((isTermsAccepted) => !isTermsAccepted)
                  setOpenTermsTooltip(false)
                }}
              />
            </Grid>
            <Grid item xs="auto">
              <Typography className={classes.terms}>
                Aceito os{' '}
                <Link
                  component="button"
                  className={classes.link}
                  onClick={() => { window.open(ProposalTerms, '_blank') }}
                >
                  Termos e Condições
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </TooltipMui>

        <Grid
          container
          spacing={3}
          className={classes.buttons}
        >
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              title="Emitir apólice"
              fullWidth
              onClick={handlePolicyIssue}
              className={isTermsAccepted ? classes.buttonHighlighted : []}
            >
              EMITIR APÓLICE
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.backButtonGrid}>
            <Link
              component="button"
              className={isTermsAccepted
                ? [classes.backButton, classes.backButtonDisabled]
                : classes.backButton
              }
              onClick={handleBackButtonClick}
            >
              VOLTAR E CORRIGIR
            </Link>
          </Grid>
        </Grid>

        <MessageHandler messages={policyErrors} type="error" />
      </Paper>

      <Loading
        isLoading={policyIsLoading}
        message="Aguarde, estamos gerando a sua apólice..."
      />
    </>
  )
}

ProposalSection.propTypes = {
  proposal: PropTypes.object.isRequired,
  createPolicy: PropTypes.func.isRequired,
  policyIsLoading: PropTypes.bool,
  policyErrors: PropTypes.array,
}

ProposalSection.defaultProps = {
  policyIsLoading: false,
  policyErrors: [],
}

export default ProposalSection

import { put, takeLatest } from 'redux-saga/effects'

import { API } from 'services/api'
import { history } from 'store'
import { PolicyTypes, PolicyActions } from 'store/ducks/policy'
import { POST, GET } from 'utils/constants/verbs'
import urls from 'utils/constants/urls'
import {
  setPolicyData,
  getPolicyData,
  setProposalData,
  getProposalData,
} from 'helpers/localStorage'
import { CREATE_POLICY, GET_POLICY } from 'utils/constants/endpoints'

export function* watchSagas() {
  yield takeLatest(PolicyTypes.CREATE_POLICY, createPolicy)
  yield takeLatest(PolicyTypes.GET_POLICY, getPolicy)
}

export function* createPolicy({ payload }) {
  try {
    const { data } = yield API({
      method: POST,
      url: CREATE_POLICY,
      data: payload,
    })
    setPolicyData({ policyId: data.id })
    const proposalData = getProposalData()
    setProposalData({ ...proposalData, isAccept: data.success })
    yield put(PolicyActions.createPolicySuccess(data))
    history.push(urls.ROUTES.policy.path)
  } catch (error) {
    yield put(PolicyActions.createPolicyFailure(error.response.data))
  }
}

export function* getPolicy({ payload }) {
  try {
    const { data } = yield API({
      method: GET,
      url: GET_POLICY(payload),
    })
    const policyData = getPolicyData()
    setPolicyData({
      ...policyData,
      premium: data.premium,
      policyNumber: data.policyNumber,
    })
    yield put(PolicyActions.getPolicySuccess(data))
  } catch (error) {
    yield put(PolicyActions.getPolicyFailure(error.response.data))
  }
}

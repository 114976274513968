const PROCESS = 'process'
const PROPOSAL = 'proposal'
const POLICY = 'policy'
const PEOPLE = 'people'
const COMPLAINANT = 'complainant'
const PROPOSAL_ACCEPT = 'proposalAccept'

//Process
export const setProcess = (process) =>
  localStorage.setItem(PROCESS, btoa(JSON.stringify(process)))

export const getProcess = () =>
  localStorage.hasOwnProperty(PROCESS)
    ? JSON.parse(atob(localStorage.getItem(PROCESS)))
    : ''

//People
export const setPeople = (people) =>
  localStorage.setItem(PEOPLE, btoa(JSON.stringify(people)))

export const getPeople = () =>
  localStorage.hasOwnProperty(PEOPLE)
    ? JSON.parse(atob(localStorage.getItem(PEOPLE)))
    : ''

//Complainant
export const setComplainant = (complainant) =>
  localStorage.setItem(COMPLAINANT, btoa(JSON.stringify(complainant)))

export const getComplainant = () =>
  localStorage.hasOwnProperty(COMPLAINANT)
    ? JSON.parse(atob(localStorage.getItem(COMPLAINANT)))
    : ''

//Proposal
export const setProposalData = (proposal) =>
  localStorage.setItem(PROPOSAL, btoa(JSON.stringify(proposal)))

export const getProposalData = () =>
  localStorage.hasOwnProperty(PROPOSAL)
    ? JSON.parse(atob(localStorage.getItem(PROPOSAL)))
    : ''

export const setProposalAccept = (proposal) =>
  localStorage.setItem(PROPOSAL_ACCEPT, btoa(JSON.stringify(proposal)))

export const getProposalAccept = () =>
  localStorage.hasOwnProperty(PROPOSAL_ACCEPT)
    ? JSON.parse(atob(localStorage.getItem(PROPOSAL_ACCEPT)))
    : ''

//Policy
export const setPolicyData = (policy) =>
  localStorage.setItem(POLICY, btoa(JSON.stringify(policy)))

export const getPolicyData = () =>
  localStorage.hasOwnProperty(POLICY)
    ? JSON.parse(atob(localStorage.getItem(POLICY)))
    : ''

import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'

import { history } from 'store'
import { AuthConsumer } from 'utils/auth/authProvider'
import urls from 'utils/constants/urls'
import {
  CookiePolicy,
  Footer,
  Header,
  Stepper,
} from 'components/core'
import {
  WhatsApp,
  IconWhatsApp,
  WhatsAppLink,
} from './styles'

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
  },
  container: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    minHeight: 'calc(100vh - 105px)',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      minHeight: 'calc(100vh - 8.3rem)',
    },
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'column',

    [theme.breakpoints.down('md')]: {
      padding: '1.25rem',
      marginLeft: 0,
      width: '100%',
    },
  },
  noHideHeaderWrapper: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '1.875rem 0',
    margin: '0 auto',
    width: '100%',
    maxWidth: 1200,
    [theme.breakpoints.down('lg')]: {
      padding: '1.25rem',
    },
  },
  hideHeaderWrapper: {
    justifyContent: 'flex-start',
    padding: 0,
    margin: 0,
    width: '100%',
  },
}))

const Layout = ({ children }) => {
  const classes = useStyles()

  const { location } = history
  const { pathname } = location

  const getLayoutParams = () => {
    const layoutParams = []

    Object.values(urls.ROUTES).forEach((key) => {
      if (key.path === pathname || pathname === '/')
        layoutParams.push(key.hideHeaderAndFooter, key.stepNumber)
    })

    return layoutParams
  }

  const [hideHeaderAndFooter, stepNumber] = getLayoutParams()

  const header = () => (
    <AuthConsumer>
      {({ isAuthenticated }) =>
        <Header hideHeaderAndFooter={hideHeaderAndFooter} />
      }
    </AuthConsumer>
  )

  return (
    <>
      <CookiePolicy />

      {header()}

      <Box component="main" className={classes.main}>
        <Box className={classes.container}>
          <Box className={[classes.wrapper, hideHeaderAndFooter ? classes.hideHeaderWrapper : classes.noHideHeaderWrapper]}>
            {!hideHeaderAndFooter && stepNumber && <Stepper stepNumber={stepNumber} />}
            {children}
          </Box>
        </Box>
      </Box>

      {!hideHeaderAndFooter && <Footer />}

      <WhatsApp id="main-cta-whatsapp">
        <WhatsAppLink
          href="https://api.whatsapp.com/send?phone=553121217777&text=Olá!"
          title="Fale por whatsapp"
          aria-label="Fale por whatsapp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconWhatsApp alt="Ícone - Pottencial" />
        </WhatsAppLink>
      </WhatsApp>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.object.isRequired,
}

export default Layout

import { createActions, createReducer } from 'reduxsauce'

export const { Types: PolicyTypes, Creators: PolicyActions } = createActions({
  createPolicy: ['payload'],
  createPolicySuccess: ['response'],
  createPolicyFailure: ['errors'],

  getPolicy: ['payload'],
  getPolicySuccess: ['response'],
  getPolicyFailure: ['errors'],

  resetErrors: [],
})

export const INITIAL_STATE = {
  isLoading: false,
  isSuccess: false,
  documentName: '',
  duration: '',
  automaticRenewalDate: '',
  policyNumber: '',
  errors: [],
}

const createPolicy = (state) => ({
  ...state,
  isLoading: true,
})

const createPolicySuccess = (state, { response }) => ({
  ...state,
  isLoading: false,
  isSuccess: response.success,
  errors: [],
})

const createPolicyFailure = (state, { errors }) => ({
  ...state,
  isLoading: false,
  isSuccess: false,
  errors,
})

const getPolicy = (state) => ({
  ...state,
  isLoading: true,
})

const getPolicySuccess = (state, { response }) => ({
  ...state,
  isLoading: false,
  isSuccess: response.success,
  documentName: response.documentName,
  duration: response.duration,
  automaticRenewalDate: response.automaticRenewalDate,
  policyNumber: response.policyNumber,
  errors: [],
})

const getPolicyFailure = (state, { errors }) => ({
  ...state,
  isLoading: false,
  isSuccess: false,
  errors,
})

const resetErrors = (state) => ({
  ...state,
  errors: [],
})

export default createReducer(INITIAL_STATE, {
  [PolicyTypes.CREATE_POLICY]: createPolicy,
  [PolicyTypes.CREATE_POLICY_SUCCESS]: createPolicySuccess,
  [PolicyTypes.CREATE_POLICY_FAILURE]: createPolicyFailure,

  [PolicyTypes.GET_POLICY]: getPolicy,
  [PolicyTypes.GET_POLICY_SUCCESS]: getPolicySuccess,
  [PolicyTypes.GET_POLICY_FAILURE]: getPolicyFailure,

  [PolicyTypes.RESET_ERRORS]: resetErrors,
})

import React from 'react'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'
import {
  Box,
  Grid,
  Button,
  Dialog,
  Backdrop,
  Typography,
  makeStyles,
  DialogContent,
} from '@material-ui/core'

import icoError from 'assets/svg/alert-danger.svg'
import icoAlert from 'assets/svg/alert-warning.svg'
import icoSuccess from 'assets/svg/alert-success.svg'

const useStyles = makeStyles((theme) => ({
  btn: {
    width: '100%',
  },
  close: {
    cursor: 'pointer',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: '16px',
    },
  },
  modal: {
    paddingBottom: theme.spacing(4),
  },
}))

const MessageBox = (props) => {
  const classes = useStyles()
  const {
    opened, type, title, text, thumb, labelPrimary, labelSecondary,
    handleClose, handlePrimary, handleSecondary, children, buttonPosition,
    maxWidth,
  } = props

  return (
    <Dialog
      open={opened}
      maxWidth={maxWidth}
      onClose={handleClose}
      className={classes.dialog}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableEscapeKeyDown
      disableBackdropClick
      fullWidth
    >
      <DialogContent className={classes.modal}>
        <CloseIcon
          title="Fechar"
          onClick={handleClose}
          className={classes.close}
        />

        {(thumb || type) && (
          <Box mt={2} mb={3} align="center">
            {type === 'error' && (<img src={icoError} alt="thumb" />)}
            {type === 'warning' && (<img src={icoAlert} alt="thumb" />)}
            {type === 'success' && (<img src={icoSuccess} alt="thumb" />)}
            {type === 'info' && thumb && (<img src={thumb} alt="thumb" />)}
          </Box>
        )}

        {title && (
          <Box mt={2} mb={3}>
            <Typography align="center" variant="h5">{title}</Typography>
          </Box>
        )}

        {text && (
          <Box mt={2} mb={3} align="center">
            <Typography align="center" dangerouslySetInnerHTML={{ __html: text }} />
          </Box>
        )}

        {children}

        {buttonPosition === 'nextTo' && (
          <Grid pb={2} container justify="center" spacing={2}>
            {handleSecondary && (
              <Grid item lg={5} sm={6} xs={12}>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.btn}
                  onClick={handleSecondary}
                  title={labelSecondary}
                >
                  {labelSecondary}
                </Button>
              </Grid>
            )}

            {handlePrimary && (
              <Grid item lg={5} sm={6} xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  title={labelPrimary}
                  className={classes.btn}
                  onClick={handlePrimary}
                >
                  {labelPrimary}
                </Button>
              </Grid>
            )}
          </Grid>
        )}

        {buttonPosition === 'below' && (
          <Grid pb={3} container justify="center" spacing={2}>
            {handlePrimary && (
              <Grid item lg={7} sm={8} xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  title={labelPrimary}
                  className={classes.btn}
                  onClick={handlePrimary}
                >
                  {labelPrimary}
                </Button>
              </Grid>
            )}

            {handleSecondary && (
              <Grid item lg={7} sm={8} xs={12}>
                <Button
                  color="primary"
                  variant="outlined"
                  title={labelSecondary}
                  className={classes.btn}
                  onClick={handleSecondary}
                >
                  {labelSecondary}
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}

MessageBox.propTypes = {
  opened: PropTypes.bool,
  children: PropTypes.element,
  buttonPosition: PropTypes.oneOf(['nextTo', 'below']),
  maxWidth: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']),
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
  handlePrimary: PropTypes.func,
  handleSecondary: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  thumb: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  labelPrimary: PropTypes.string,
  labelSecondary: PropTypes.string,
}

MessageBox.defaultProps = {
  type: 'info',
  maxWidth: 'sm',
  buttonPosition: 'nextTo',
  opened: false,
  text: undefined,
  thumb: undefined,
  title: undefined,
  labelPrimary: 'Confirmar',
  labelSecondary: 'Cancelar',
  handlePrimary: undefined,
  handleSecondary: undefined,
  children: undefined,
}

export default MessageBox

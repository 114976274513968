import { put, takeLatest } from 'redux-saga/effects'

import urls from 'utils/constants/urls'
import { history } from 'store'
import { API } from 'services/api'
import { ProposalTypes, ProposalActions } from 'store/ducks/proposal'
import { GET, POST } from 'utils/constants/verbs'
import { setProposalData, getProposalData, getComplainant, setProposalAccept } from 'helpers/localStorage'
import { CREATE_PROPOSAL, GET_PROPOSAL, ACCEPT_PROPOSAL } from 'utils/constants/endpoints'

export function* watchSagas() {
  yield takeLatest(ProposalTypes.CREATE_PROPOSAL, createProposal)
  yield takeLatest(ProposalTypes.GET_PROPOSAL, getProposal)
  yield takeLatest(ProposalTypes.ACCEPT_PROPOSAL, acceptProposal)
}

export function* createProposal({ payload }) {
  try {
    const { data } = yield API({
      method: POST,
      url: CREATE_PROPOSAL,
      data: payload,
    })
    const proposalData = getProposalData()
    setProposalData({
      ...proposalData,
      status: 'proposal',
      proposalId: data.proposalId,
    })
    yield put(ProposalActions.createProposalSuccess(data))
  } catch (error) {
    yield put(
      ProposalActions.createProposalFailure(error.response.data)
    )
  }
}

export function* getProposal({ payload }) {
  try {
    const { data } = yield API({
      method: GET,
      url: GET_PROPOSAL(payload),
    })
    const proposalData = getProposalData()
    const complainant = getComplainant()
    let isComplainantWrong = false
    data.participants.filter(p => p.role === 'Beneficiary').map((b) => isComplainantWrong = complainant.id !== b.id)
    setProposalData({
      ...proposalData,
      premium: data.premium,
      documentName: data.documentName,
      isComplainantWrong,
    })
    yield put(ProposalActions.getProposalSuccess(data))
  } catch (error) {
    yield put(ProposalActions.getProposalFailure(error.response.data))
  }
}

export function* acceptProposal({ payload }) {
  try {
    const { data } = yield API({
      method: POST,
      url: ACCEPT_PROPOSAL(payload),
    })
    setProposalAccept({
      id: payload,
      accept: true
    })
    yield put(ProposalActions.acceptProposalSuccess(data))
  } catch (error) {
    yield put(ProposalActions.acceptProposalFailure(error.response.data))
    history.push(urls.ROUTES.error.path)
  }
}
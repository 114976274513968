import { getProposalData } from 'helpers/localStorage'

const { number } = getProposalData()

const BASE = ''
const SIGNIN_CALLBACK = 'callback'
const SILENT_RENEW_CALLBACK = 'silent-renew'
const SIGNOUT_CALLBACK = 'signout-callback'
const AUTH = 'autenticacao'
const REGISTER = 'cadastro'
const REGISTER_ADDRESS = 'cadastrar-endereco'
const SIMULATE_QUOTE = 'simular-cotacao'
const PROPOSAL = 'proposta'
const POLICY = 'apolice'
const ERROR = 'erro'

const urls = {
  ROUTES: {
    app: {
      path: `${BASE}/`,
      name: 'Início',
      stepNumber: 1,
    },
    signinCallback: {
      path: `${BASE}/${SIGNIN_CALLBACK}`,
    },
    silentRenewCallback: {
      path: `${BASE}/${SILENT_RENEW_CALLBACK}`,
    },
    signoutCallback: {
      path: `${BASE}/${SIGNOUT_CALLBACK}`,
    },
    auth: {
      path: `${BASE}/${AUTH}`,
      hideHeaderAndFooter: true,
    },
    register: {
      path: `${BASE}/${REGISTER}`,
      hideHeaderAndFooter: true,
    },
    registerAddress: {
      path: `${BASE}/${REGISTER_ADDRESS}`,
      name: 'Cadastrar endereço',
      stepNumber: 2,
    },
    simulator: {
      path: `${BASE}/${SIMULATE_QUOTE}`,
      name: 'Simular',
      stepNumber: 3,
    },
    proposal: {
      path: `${BASE}/${PROPOSAL}`,
      name: 'Processo',
      stepNumber: 4,
      parameter: number,
    },
    policy: {
      path: `${BASE}/${PROPOSAL}/${POLICY}`,
      name: 'Apólice',
      stepNumber: 5,
    },
    error: {
      path: `${BASE}/${ERROR}`,
      name: 'Algo deu errado',
    },
  },
  LINKS: {
    app: `${BASE}/`,
    privacyPolicy:
      'https://static.pottencial.com.br/sitefiles/Governanca/Politica-Governanca-Privacidade-Dados.pdf',
    cookiePolicy:
      'https://static.pottencial.com.br/sitefiles/Governanca/Politica-cookies-v001.pdf',
  },
}

export default urls

import React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'

import { history } from 'store'
import urls from 'utils/constants/urls'
import userManager from 'utils/auth/userManager'
import { AuthConsumer } from 'utils/auth/authProvider'

import AuthContainer from 'containers/Auth'
import SigninCallback from 'containers/Auth/Callbacks/SigninCallback'
import SilentRenewCallback from 'containers/Auth/Callbacks/SilentRenewCallback'
import SignoutCallback from 'containers/Auth/Callbacks/SignoutCallback'
import HomeContainer from 'containers/Home'
import SimulateQuote from 'containers/Process/SimulateQuote'
import ProposalContainer from 'containers/Proposal'
import PolicyFeedbackContainer from 'containers/PolicyFeedback'
import ErrorFeedbackContainer from 'containers/ErrorFeedback'
import RegisterAddress from 'containers/RegisterAddress'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <AuthConsumer>
        {({ isAuthenticated }) =>
          isAuthenticated() ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: urls.ROUTES.auth.path,
              }}
            />
          )
        }
      </AuthConsumer>
    )}
  />
)

const Routes = () => {
  const { ROUTES } = urls

  const signinRedirect = () => {
    userManager.signinRedirect()
    return <></>
  }

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path={ROUTES.app.path} component={HomeContainer} />
        <Route
          exact
          path={ROUTES.auth.path}
          render={() => (
            <AuthConsumer>
              {({ isAuthenticated }) =>
                isAuthenticated() ? (
                  <Redirect to={ROUTES.app.path} />
                ) : (
                  signinRedirect()
                )
              }
            </AuthConsumer>
          )}
        />
        <Route exact path={ROUTES.register.path} component={AuthContainer} />
        <Route
          exact
          path={ROUTES.signinCallback.path}
          component={SigninCallback}
        />
        <Route
          exact
          path={ROUTES.silentRenewCallback.path}
          component={SilentRenewCallback}
        />
        <Route
          exact
          path={ROUTES.signoutCallback.path}
          component={SignoutCallback}
        />
        <Route
          exact
          path={ROUTES.error.path}
          component={ErrorFeedbackContainer}
        />
        <PrivateRoute
          exact
          path={ROUTES.proposal.path}
          component={ProposalContainer}
        />
        <PrivateRoute
          exact
          path={ROUTES.policy.path}
          component={PolicyFeedbackContainer}
        />
        <Route
          exact
          path={ROUTES.registerAddress.path}
          component={RegisterAddress}
        />
        <PrivateRoute
          exact
          path={ROUTES.simulator.path}
          component={SimulateQuote}
        />
      </Switch>
    </ConnectedRouter>
  )
}

export default Routes

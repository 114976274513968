import { put, takeLatest } from 'redux-saga/effects'
import { API_AUTH_SIGNUP } from 'services/api'
import { AuthTypes, AuthActions } from 'store/ducks/auth'
import { POST } from 'utils/constants/verbs'
import { SIGNUP } from 'utils/constants/endpoints'

export function* watchSagas() {
  yield takeLatest(AuthTypes.SIGNUP, signup)
}

export function* signup({ payload }) {
  try {
    const { data } = yield API_AUTH_SIGNUP({
      method: POST,
      url: SIGNUP,
      data: payload,
    })
    yield put(AuthActions.signupSuccess({ success: data }, payload.email))
  } catch (error) {
    yield put(AuthActions.signupFailure(error.response.data))
  }
}

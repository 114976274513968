const getProcessObject = (
  processNumber,
  processData,
  complainantData,
  riskObjectText
) => {
  const processObject = {
    productKey: 'garantiaRecursal',
    properties: {
      PAPEL_USUARIO: processData.persona,
      NUMERO_PROCESSO: processNumber,
      NUMERO_COMARCA: processData.process.comarca,
      TIPO_RECURSO: processData.process.resourceType.description,
      CNPJ_LICITANTE: processData.defendant.document,
      CNPJ_SEGURADO: complainantData.document,
      VALOR_CONTRATO: processData.process.resourceType.maxInsuredAmount,
      VIGENCIA_CONTRATO: 5,
      BROKER_DOCUMENT: processData.broker?.documentNumber,
      TEXTO_OBJETO: riskObjectText,
      DATA_INICIO_VIGENCIA: new Date(new Date().setHours(0, 0, 0, 0)),
    },
  }

  return processObject
}

export default getProcessObject

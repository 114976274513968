import React from 'react'
import PropTypes from 'prop-types'
import Lottie from 'react-lottie'
import { Box, makeStyles, Typography } from '@material-ui/core'

import loading from 'assets/loading/loading.json'
import inputLoading from 'assets/loading/inputLoading.json'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column',
    margin: '0 auto',
    width: '1.25rem',
    textAlign: 'center',
  },
  loadingInput: {
    position: 'absolute',
    bottom: '0.3125rem',
    right: '0.3125rem',
  },
  loading: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    background: '#00000030',
    [theme.breakpoints.down('md')]: {
      padding: '1.25rem',
    },
  },
  svg: {
    width: '3.125rem',
  },
  svgInput: {
    width: '1.2rem',
  },
  card: {
    maxWidth: 440,
    borderRadius: 14,
    boxShadow: '3px 3px 10px #00000029',
    backgroundColor: 'var(--white)',
    padding: '24px 24px 32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const Loading = ({ isLoading, children, isInput, message }) => {
  const classes = useStyles()

  if (isLoading)
    return (
      <>
        <Box className={[classes.container, isInput ? classes.loadingInput : classes.loading]}>
          {isInput && (
            <Box className={classes.svgInput}>
              <Lottie
                options={{
                  animationData: inputLoading,
                }}
              />
            </Box>
          )}

          {!isInput && (
            <>
              <Box className={classes.card}>
                <Box className={classes.svg}>
                  <Lottie
                    options={{
                      animationData: loading,
                    }}
                  />
                </Box>
                <Box>
                  <Typography align="center" variant="h6">
                    {message}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </>
    )

  return children
}

Loading.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.any,
  isInput: PropTypes.bool,
  message: PropTypes.string,
}

Loading.defaultProps = {
  isLoading: false,
  children: null,
  isInput: false,
  message: 'Carregando...',
}

export default Loading

import axios from 'axios'

import userManager from 'utils/auth/userManager'
import { BASE_URL } from 'utils/constants/endpoints'
import urls from 'utils/constants/urls'
import { history } from 'store'

const API = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
})

API.interceptors.request.use(async (initialConfig) => {
  const config = initialConfig
  const user = await userManager.getUser()

  if (user) {
    config.headers.Authorization = `Bearer ${user.access_token}`
  }

  return config
})

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      await userManager.signoutRedirect()
    } else if (
      error?.response?.status === 400 ||
      error?.response?.status === 404 ||
      error?.response?.status === 502
    ) {
      throw error
    } else {
      history.push(urls.ROUTES.error.path)
      throw error
    }
  }
)

const API_AUTH_SIGNUP = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
})

API_AUTH_SIGNUP.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 400 || error?.response?.status === 502) {
      throw error
    } else {
      history.push(urls.ROUTES.error.path)
      throw error
    }
  }
)

export { API, API_AUTH_SIGNUP }

import { all } from 'redux-saga/effects'
import * as authSaga from './auth'
import * as resourceSaga from './resource'
import * as peopleSaga from './people'
import * as addressSaga from './address'
import * as processSaga from './process'
import * as proposalSaga from './proposal'
import * as policySaga from './policy'

function* Sagas() {
  yield all([
    authSaga.watchSagas(),
    resourceSaga.watchSagas(),
    peopleSaga.watchSagas(),
    addressSaga.watchSagas(),
    processSaga.watchSagas(),
    proposalSaga.watchSagas(),
    policySaga.watchSagas(),
  ])
}

export default Sagas

import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import { Loading, MessageHandler } from 'components/core'
import ProcessSection from './ProcessSection'
import ProposalSection from './ProposalSection'

const Proposal = ({ isLoading, acceptIsLoading, proposal, isSuccess, errors, ...props }) => (
  <Loading
    isLoading={isLoading}
    message="Aguarde, estamos carregando os dados da sua proposta..."
  >
    {!isSuccess ? (
      <MessageHandler messages={errors} type="error" />
    ) : (
      <Grid container direction="row" spacing={3}>
        <Grid item md xs={12}>
          <ProcessSection proposal={proposal} {...props} />
        </Grid>

        <Grid item md="auto" xs={12}>
          <ProposalSection proposal={proposal} {...props} />
        </Grid>
      </Grid>
    )}

    <Loading isLoading={acceptIsLoading} />
  </Loading>
)

Proposal.propTypes = {
  proposal: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  errors: PropTypes.array.isRequired,
}

export default Proposal

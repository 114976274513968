import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'

import { ReactComponent as IconProposal } from 'assets/svg/icon-proposal.svg'
import { ReactComponent as IconEmail } from 'assets/svg/icon-email.svg'
import { ReactComponent as IconDownload } from 'assets/svg/icon-download.svg'
import iconClose from 'assets/svg/icon-close-white.svg'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    background: 'var(--black-rbg)',
    transitionProperty: 'opacity, visibility, transform',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0.5, 0, 0, 1)',

    opacity: 0,
    visibility: 'hidden',
  },
  wrapperOpen: {
    opacity: 1,
    visibility: 'visible',
  },
  container: {
    position: 'relative',
    margin: '0 auto',
    width: 800,
    maxWidth: 800,
    minHeight: 362,
    maxHeight: '95vh',
    overflowY: 'auto',
    background: 'var(--white)',
    borderRadius: 20,
    boxShadow: '0px 3px 6px #00000029',
    display: 'flex',
    justifyContent: 'center',
    padding: '1.875rem 2.5rem 2.5rem',

    [theme.breakpoints.down('lg')]: {
      width: '90%',
      minWidth: 720,
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
      minWidth: 300,
    },
  },
  closeWrapper: {
    position: 'absolute',
    right: '2%',
    top: '3%',
    padding: 4,
    cursor: 'pointer',
    borderRadius: 17,
    content: `url(${iconClose})`,
    backgroundColor: 'var(--primary)',
    zIndex: 1,
  },
  content: {
    width: '100%',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'var(--font-poppins)',
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  subTitle: {
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'var(--font-poppins)',
    color: theme.palette.text.primary,
    textAlign: 'center',
  },
  text: {
    fontSize: 14,
    color: theme.palette.text.primary,
    textAlign: 'center',
    padding: '0 12px',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  stepGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  stepNumber: {
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: 32,
    margin: '1.25rem 0 0.125rem',
    color: 'var(--primary)',
    width: 25,
    height: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 25,
    height: 50,
    margin: '0 0 1.875rem',
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
}))

const HowItWorksModal = ({
  isOpen,
  setOpen,
}) => {
  const classes = useStyles()

  return (
    <>
      <Box className={isOpen ? [classes.wrapper, classes.wrapperOpen] : classes.wrapper}>
        <Box className={classes.container}>
          <Box className={classes.closeWrapper} onClick={() => setOpen(false)} />

          <Box className={classes.content}>
            <Typography component="h1" className={classes.title}>
              Como a emissão do seguro funciona?
            </Typography>

            <Grid container>
              <Grid item sm={4} xs={12} className={classes.stepGrid}>
                <Box className={classes.stepNumber}>1</Box>

                <IconProposal className={classes.icon} />

                <Typography component="h2" className={classes.subTitle}>
                  Emita a apólice!
                </Typography>

                <Typography component="h1" className={classes.text}>
                  Esse é o passo já foi concluído.
                </Typography>
              </Grid>
              <Grid item sm={4} xs={12} className={classes.stepGrid}>
                <Box className={classes.stepNumber}>2</Box>
                <IconEmail className={classes.icon} />

                <Typography component="h2" className={classes.subTitle}>
                  Verifique seu e-mail
                </Typography>

                <Typography component="h1" className={classes.text}>
                  Verifique a caixa de entrada do seu e-mail e confirme o
                  recebimento dos documentos: apólice, boleto e certidões.
                </Typography>
              </Grid>
              <Grid item sm={4} xs={12} className={classes.stepGrid}>
                <Box className={classes.stepNumber}>3</Box>
                <IconDownload className={classes.icon} />
                <Typography component="h2" className={classes.subTitle}>
                  Faça o download
                </Typography>

                <Typography component="h1" className={classes.text}>
                  Baixe os documentos e envie para seu advogado
                  para que ele possa dar andamento ao seu recurso!
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  )
}

HowItWorksModal.propTypes = {
  setOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
}

HowItWorksModal.defaultValues = {
  isOpen: false,
}

export default HowItWorksModal

import React from 'react'

import { AuthConsumer } from 'utils/auth/authProvider'
import Loading from 'components/core/Loading'

const SignoutCallback = () => (
  <AuthConsumer>
    {({ signoutRedirectCallback }) => {
      signoutRedirectCallback()
      return <Loading isLoading={true} />
    }}
  </AuthConsumer>
)

export default SignoutCallback

import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Hidden,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core'

import { history } from 'store'
import urls from 'utils/constants/urls'
import useUtils from 'hooks/useUtils'
import {
  HowItWorksModal,
  Loading,
  MessageHandler,
  Tooltip,
} from 'components/core'
import { CurrencyInput } from 'components'
import quoteValueImage from 'assets/images/image-quote-value.png'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    marginBottom: 10,
    padding: 24,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'var(--font-poppins)',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  titleLine: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    width: 30,
    height: 3,
    background: theme.palette.primary.main,
  },
  formControl: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  currencyInput: {
    '& .MuiFormLabel-root': {
      width: '133%',
    },
  },
  link: {
    textDecoration: 'underline',
  },
  linkBottom: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  valuesPaper: {
    width: '100%',
    backgroundColor: theme.palette.background.gray,
    marginTop: theme.spacing(4),
    padding: 24,
  },
  image: {
    content: `url(${quoteValueImage})`,
    width: 72,
  },
  premiumGrid: {
    [theme.breakpoints.up('sm')]: {
      borderRight: '1px solid #D6D6D6',
    },
  },
  insuredAmountGrid: {
    [theme.breakpoints.up('md')]: {
      borderRight: '1px solid #D6D6D6',
    },
  },
  premium: {
    fontSize: '1.75rem',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
    },
  },
  insuredAmount: {
    fontSize: '1.75rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  currencyDescription: {
    fontSize: '0.625rem',
    display: 'flex',
    textAlign: 'center',
    letterSpacing: 1,
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.75rem',
    },
  },
  infoGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  infoText: {
    fontSize: '0.75rem',
    display: 'flex',
    textAlign: 'center',
    maxWidth: '15.75rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.875rem',
      maxWidth: '18.375rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  buttons: {
    marginTop: theme.spacing(3),
  },
}))

const FormSimulator = ({
  resourceData,
  validityData,
  quoteData,
  quoteIsLoading,
  quoteSuccess,
  updateQuote,
  updateLoading,
  updateErrors,
  hasDisable,
  handleRedirectProposal,
  hasDisableButton,
}) => {
  const classes = useStyles()
  const { formatCurrency } = useUtils()

  const formik = useFormik({
    initialValues: {
      ...{
        resourceType: '',
        contractValue: '',
        yearsValid: '',
      }
    },
    validationSchema: Yup.object().shape({
      resourceType: Yup.string()
        .required('Selecione um tipo de Recurso.'),
      contractValue: Yup.string()
        .required('Digite o valor do depósito que o seguro irá garantir.'),
      yearsValid: Yup.string()
        .required('Selecione um prazo em anos de vigência da apólice.')
    }),
    onSubmit: (formData) => {
      const data = {
        quote: {
          resourceType: formData.resourceType,
          contractValue: formData.contractValue,
          duration: formData.yearsValid,
        },
      }
      updateQuote(data)
    },
  })

  const [resourceTypeData, setResourceTypeData] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [disableCalc, setDisableCalc] = useState(null)
  const [quoteUpdateByResourcePending, setQuoteUpdateByResourcePending] = useState(false)

  const resourceTypeValue = quoteData?.resourceType
  const contractValueValue = `${quoteData?.contractValue}`
  const yearsValidValue = `${quoteData?.duration}`

  const isContractValueUpdated =
    !isEmpty(contractValueValue) &&
    parseFloat(contractValueValue.toString()) === parseFloat(formik.getFieldProps('contractValue').value.toString())

  const isCalcUpdated =
    resourceTypeValue === formik.getFieldProps('resourceType').value &&
    isContractValueUpdated &&
    yearsValidValue === formik.getFieldProps('yearsValid').value

  const isCalcPendingByYearsValid =
    yearsValidValue !== formik.getFieldProps('yearsValid').value

  const hasContractValueError = useCallback(() => {
    const contractValue = formik.getFieldProps('contractValue').value

    if (contractValue.length === 0) {
      return {
        error: true,
        message: 'Digite o valor do depósito que o seguro irá garantir.'
      }
    }
    if (parseFloat(contractValue) > parseFloat(resourceTypeData?.maxInsuredAmount)) {
      return {
        error: true,
        message: 'Valor maior que o permitido para este recurso.'
      }
    }
    return {
      error: false,
      message: ''
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.getFieldProps('contractValue').value, resourceTypeData?.maxInsuredAmount])

  useEffect(() => {
    if (quoteSuccess) {
      formik.setFieldValue('resourceType', resourceTypeValue)
      formik.setFieldValue('contractValue', contractValueValue)
      formik.setFieldValue('yearsValid', yearsValidValue)
      resourceData.filter(r => r.description === resourceTypeValue).map((t) => setResourceTypeData(t))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    quoteSuccess,
    resourceTypeValue,
    contractValueValue,
    yearsValidValue,
    setResourceTypeData,
    resourceData
  ])

  useEffect(() => {
    if (isCalcUpdated || disableCalc !== null) {
      setDisableCalc(isCalcUpdated)
      hasDisable(disableCalc)
    }
  }, [isCalcUpdated, disableCalc, hasDisable])

  const handleBackButtonClick = () => {
    history.push({
      pathname: urls.ROUTES.app.path,
    })
  }

  const handleSubmitOnChange = () => {
    const isDataValid =
      formik.getFieldProps('contractValue').value !== undefined &&
      formik.getFieldProps('contractValue').value > 0 &&
      formik.getFieldProps('resourceType').value !== undefined &&
      formik.getFieldProps('yearsValid').value !== undefined &&
      !(parseFloat(formik.getFieldProps('contractValue').value) > parseFloat(resourceTypeData?.maxInsuredAmount))

    if (isDataValid && !isCalcUpdated && quoteSuccess) {
      var data = {
        'quote': {
          'resourceType': formik.getFieldProps('resourceType').value,
          'contractValue': formik.getFieldProps('contractValue').value,
          'duration': formik.getFieldProps('yearsValid').value,
        }
      }
      updateQuote(data)
    }
  }

  useEffect(() => {
    if (quoteUpdateByResourcePending && quoteUpdateByResourcePending === resourceTypeData?.description) {

      formik.setFieldValue('contractValue', resourceTypeData?.maxInsuredAmount)

      var data = {
        'quote': {
          'resourceType': formik.getFieldProps('resourceType').value,
          'contractValue': resourceTypeData?.maxInsuredAmount,
          'duration': formik.getFieldProps('yearsValid').value,
        }
      }
      updateQuote(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteUpdateByResourcePending, resourceTypeData?.description])

  const handleOnResourceTypeChange = (event) => {
    const resourceType = event.target.value

    formik.setFieldValue('resourceType', resourceType)
    resourceData.filter(r => r.description === resourceType).map((t) => setResourceTypeData(t))

    if (resourceTypeValue !== resourceType) {
      setQuoteUpdateByResourcePending(resourceType)
    }
  }

  useEffect(() => {
    if (isCalcPendingByYearsValid) {
      handleSubmitOnChange()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCalcPendingByYearsValid])

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container direction="row" alignItems="center" justify="space-between">
          <Grid item sm="auto" xs={12}>
            <Typography component="h1" className={classes.title}>
              Opções do seguro:
            </Typography>

            <span className={classes.titleLine} />
          </Grid>
          <Grid item sm="auto" xs={12}>
            <Hidden xsDown>
              <Link
                component="button"
                variant="body2"
                className={classes.link}
                onClick={() => setOpenModal((openModal) => !openModal)}
              >
                Como funciona a emissão do seguro?
              </Link>
            </Hidden>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <FormControl
              className={classes.formControl}
              error={formik.touched.resourceType && !!formik.errors.resourceType}
            >
              <InputLabel color="secondary">Tipo de recurso</InputLabel>
              <Select
                id="resourceType"
                color="secondary"
                fullWidth
                {...formik.getFieldProps('resourceType')}
                onChange={handleOnResourceTypeChange}
              >
                {resourceData.map((type) => (
                  <MenuItem key={type.description} value={type.description}>{type.description}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.touched.resourceType && formik.errors.resourceType}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={12}>
            <CurrencyInput
              fullWidth
              color="secondary"
              id="contractValue"
              label={(
                <>
                  <span>Valor do depósito que o seguro irá garantir</span>
                  <Box display="inline" ml={0.5}>
                    <Tooltip title="Este valor será acrescido de, no mínimo, 30%
                      sobre o valor da condenação, observados os
                      limites estabelecidos pela Lei 8.​177 e pela
                      Instrução normativa nº 3 do TST, nos termos do
                      art. 3º, inciso II, do Ato Conjunto TST, CSJT nº1/2019." />
                  </Box>
                </>
              )}
              title="Valor do depósito que o seguro irá garantir"
              className={[classes.formControl, classes.currencyInput]}
              {...formik.getFieldProps('contractValue')}
              error={(formik.touched.contractValue && !!formik.errors.contractValue) || hasContractValueError().error}
              helperText={(formik.touched.contractValue && formik.errors.contractValue) || hasContractValueError().message}
              onChange={(event, value) => formik.setFieldValue('contractValue', value || '')}
              onBlur={handleSubmitOnChange}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <FormControl
              className={classes.formControl}
              error={formik.touched.yearsValid && !!formik.errors.yearsValid}
            >
              <InputLabel color="secondary">Prazo em anos de vigência da apólice</InputLabel>
              <Select
                id="yearsValid"
                color="secondary"
                fullWidth
                {...formik.getFieldProps('yearsValid')}
              >
                {validityData !== undefined && validityData.map((type) => (
                  <MenuItem key={type.validityPeriodInYears} value={type.validityPeriodInYears}>{type.validityPeriodInYears}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{formik.touched.yearsValid && formik.errors.yearsValid}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <MessageHandler messages={updateErrors} type="error" />

        <Paper elevation={0} className={classes.valuesPaper}>
          <Grid container direction="row">
            <Hidden smDown>
              <Grid item xs="auto">
                <span className={classes.image} />
              </Grid>
            </Hidden>

            <Grid item xs>
              <Grid container direction="row" alignItems="center" justify="center" spacing={3}>
                <Grid item md={4} sm={6} xs={12} className={classes.premiumGrid}>
                  <Grid container direction="column" alignItems="center">
                    <Grid item xs={12}>
                      <Typography component="span" className={classes.premium}>
                        {formatCurrency(quoteData?.premium)}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography component="span" className={classes.currencyDescription}>
                        À VISTA NO BOLETO BANCÁRIO
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={4} sm={6} xs={12} className={classes.insuredAmountGrid}>
                  <Grid container direction="column" alignItems="center">
                    <Grid item xs={12}>
                      <Typography component="span" className={classes.insuredAmount}>
                        {formatCurrency(quoteData?.insuredAmount)}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography component="span" className={classes.currencyDescription}>
                        VALOR SEGURADO + AGRAVO
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={4} xs={12} className={classes.infoGrid}>
                  <Typography component="span" className={classes.infoText}>
                    O valor do seguro varia de acordo com as
                    opções escolhidas em sua cotação.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Hidden smUp>
          <Link
            component="button"
            variant="body2"
            className={[classes.link, classes.linkBottom]}
            onClick={() => setOpenModal((openModal) => !openModal)}
          >
            Como funciona a emissão do seguro?
          </Link>
        </Hidden>
      </Paper>

      <Grid pb={2} container justify="flex-end" spacing={2} className={classes.buttons}>
        <Grid item lg={3} sm={4} xs={12}>
          <Button
            color="primary"
            variant="outlined"
            title="Voltar"
            fullWidth
            onClick={handleBackButtonClick}
          >
            Voltar
          </Button>
        </Grid>

        <Grid item lg={3} sm={4} xs={12}>
          <Button
            color="primary"
            variant="contained"
            title="Próximo"
            fullWidth
            onClick={handleRedirectProposal}
            disabled={!hasDisableButton}
          >
            Próximo
          </Button>
        </Grid>
      </Grid>

      <HowItWorksModal
        isOpen={openModal}
        setOpen={setOpenModal}
      />

      <Loading isLoading={quoteIsLoading} />

      <Loading
        isLoading={updateLoading}
        message="Aguarde alguns instantes, estamos calculando"
      />
    </>
  )
}

FormSimulator.propTypes = {
  resourceData: PropTypes.array,
  validityData: PropTypes.array,
  quoteData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  quoteIsLoading: PropTypes.bool,
  quoteSuccess: PropTypes.bool,
  updateQuote: PropTypes.func.isRequired,
  updateLoading: PropTypes.bool,
  updateErrors: PropTypes.array,
  hasDisable: PropTypes.func.isRequired,
  handleRedirectProposal: PropTypes.func.isRequired,
  hasDisableButton: PropTypes.bool,
}

FormSimulator.defaultProps = {
  resourceData: [],
  validityData: [],
  quoteData: [],
  quoteIsLoading: false,
  quoteSuccess: false,
  updateLoading: false,
  updateErrors: [],
  hasDisableButton: null,
}

export default FormSimulator

import { createActions, createReducer } from 'reduxsauce'

export const { Types: PeopleTypes, Creators: PeopleActions } = createActions({
  getDefendant: ['payload'],
  getDefendantSuccess: ['response'],
  getDefendantFailure: ['errors'],

  getComplainant: ['payload'],
  getComplainantSuccess: ['response'],
  getComplainantFailure: ['errors'],

  resetComplainant: [],
  resetPeople: [],
})

export const INITIAL_STATE = {
  defendant: {
    isLoading: false,
    isSuccess: false,
    data: {},
    name: '',
    errors: [],
  },
  complainant: {
    isLoading: false,
    isSuccess: false,
    data: {},
    name: '',
    errors: [],
  },
}

const getDefendant = (state) => ({
  ...state,
  defendant: {
    isLoading: true,
  },
})

const getDefendantSuccess = (state, { response }) => ({
  ...state,
  defendant: {
    isLoading: false,
    isSuccess: response.success,
    data: response.result,
  },
})

const getDefendantFailure = (state, { errors }) => ({
  ...state,
  defendant: {
    isSuccess: false,
    errors,
  },
})

const getComplainant = (state) => ({
  ...state,
  complainant: {
    isLoading: true,
    data: {},
  },
})

const getComplainantSuccess = (state, { response }) => ({
  ...state,
  complainant: {
    isLoading: false,
    isSuccess: response.success,
    data: response.result,
  },
})

const getComplainantFailure = (state, { errors }) => ({
  ...state,
  complainant: {
    isSuccess: false,
    errors,
  },
})

const resetComplainant = (state) => ({
  ...state,
  complainant: INITIAL_STATE.complainant,
})

const resetPeople = () => ({
  ...INITIAL_STATE,
})

export default createReducer(INITIAL_STATE, {
  [PeopleTypes.GET_DEFENDANT]: getDefendant,
  [PeopleTypes.GET_DEFENDANT_SUCCESS]: getDefendantSuccess,
  [PeopleTypes.GET_DEFENDANT_FAILURE]: getDefendantFailure,

  [PeopleTypes.GET_COMPLAINANT]: getComplainant,
  [PeopleTypes.GET_COMPLAINANT_SUCCESS]: getComplainantSuccess,
  [PeopleTypes.GET_COMPLAINANT_FAILURE]: getComplainantFailure,

  [PeopleTypes.RESET_COMPLAINANT]: resetComplainant,
  [PeopleTypes.RESET_PEOPLE]: resetPeople,
})

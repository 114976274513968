import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'

import urls from 'utils/constants/urls'
import { ReactComponent as LogoComponent } from 'assets/svg/logo.svg'

const useStyles = makeStyles((theme) => ({
  noHideHeader: {
    width: 170,
    height: 64,
    position: 'absolute',
    top: 0,
    [theme.breakpoints.down('sm')]: {
      width: 145,
    },
  },
  hideHeader: {
    width: 128,
  }
}))

const Logo = ({ hideHeaderAndFooter }) => {
  const classes = useStyles()

  return (
    <Link to={urls.ROUTES.app.path}>
      <LogoComponent className={hideHeaderAndFooter ? classes.hideHeader : classes.noHideHeader} />
    </Link>
  )
}

Logo.propTypes = {
  hideHeaderAndFooter: PropTypes.bool,
}

Logo.defaultPops = {
  hideHeaderAndFooter: false,
}

export default Logo

import { createActions, createReducer } from 'reduxsauce'
import { getProcess } from 'helpers/localStorage'

export const { Types: ProcessTypes, Creators: ProcessActions } = createActions({
  searchProcess: ['payload'],
  searchProcessSuccess: ['response'],
  searchProcessFailure: ['errors'],

  searchBroker: ['payload'],
  searchBrokerSuccess: ['response'],
  searchBrokerFailure: ['errors'],

  verifyBrokerAppointment: ['payload'],
  verifyBrokerAppointmentSuccess: ['response'],
  verifyBrokerAppointmentFailure: ['errors'],

  setStep: ['step', 'isBack'],
  setProcess: ['payload'],
  setIdentifyUser: ['payload'],

  saveProcess: ['payload'],
  saveProcessSuccess: ['response'],
  saveProcessFailure: ['errors'],

  getQuote: ['payload'],
  getQuoteSuccess: ['response'],
  getQuoteFailure: ['errors'],

  updateQuote: ['payload'],
  updateQuoteSuccess: ['response'],
  updateQuoteFailure: ['errors'],

  resetProcess: [],
  resetErrors: [],
  resetBrokerAppointment: [],
})

export const INITIAL_STATE = {
  search: {
    isLoading: false,
    errors: [],
  },
  brokerSearch: {
    isLoading: false,
    data: [],
    errors: [],
  },
  brokerAppointment: {
    isLoading: false,
    allowBrokerSearch: false,
    data: {},
    errors: [],
  },
  form: {
    step: 1,
    isBack: false,
  },
  save: {
    isLoading: false,
    isSuccess: false,
    errors: [],
  },
  quote: {
    isLoading: false,
    isSuccess: false,
    data: [],
    errors: [],
  },
  validation: {
    denied: false,
  },
  update: {
    isLoading: false,
    isSuccess: false,
    errors: [],
  },
  processData: {},
  processNumber: '',
  identification: {},
}

const searchProcess = () => ({
  ...INITIAL_STATE,
  search: {
    isLoading: true,
  },
})

const searchProcessSuccess = (state, { response }) => ({
  ...state,
  search: {
    isLoading: false,
    data: response,
  },
  processNumber: getProcess(),
})

const searchProcessFailure = (state, { errors }) => ({
  ...state,
  search: {
    isLoading: false,
    errors,
  },
})

const searchBroker = (state) => ({
  ...state,
  brokerSearch: {
    isLoading: true,
  },
  errors: [],
})

const searchBrokerSuccess = (state, { response }) => ({
  ...state,
  brokerSearch: {
    isLoading: false,
    data: response.result,
  },
})

const searchBrokerFailure = (state, { errors }) => ({
  ...state,
  brokerSearch: {
    isLoading: false,
    data: [],
    errors,
  },
})

const verifyBrokerAppointment = (state) => ({
  ...state,
  brokerAppointment: {
    isLoading: true,
    allowBrokerSearch: false,
    data: {},
  },
})

const verifyBrokerAppointmentSuccess = (state, { response }) => ({
  ...state,
  brokerAppointment: {
    isLoading: false,
    allowBrokerSearch: !response.result.isActual,
    data: response.result,
  },
})

const verifyBrokerAppointmentFailure = (state, { errors }) => ({
  ...state,
  brokerAppointment: {
    isLoading: false,
    allowBrokerSearch: false,
    data: {},
    errors,
  },
})

const getQuote = (state) => ({
  ...state,
  quote: {
    isLoading: true,
  },
})

const getQuoteSuccess = (state, { response }) => ({
  ...state,
  quote: {
    isLoading: false,
    data: response.result,
    isSuccess: response.success,
  },
})

const getQuoteFailure = (state, { errors }) => ({
  ...state,
  quote: {
    isLoading: false,
    isSuccess: false,
    errors,
  },
})

const updateQuote = (state) => ({
  ...state,
  update: {
    isLoading: true,
  },
})

const updateQuoteSuccess = (state, { response }) => ({
  ...state,
  update: {
    isLoading: false,
    isSuccess: response.success,
  },
})

const updateQuoteFailure = (state, { errors }) => ({
  ...state,
  update: {
    isLoading: false,
    isSuccess: false,
    errors,
  },
})

const setStep = (state, { step, isBack }) => ({
  ...state,
  form: {
    step,
    isBack,
  },
})

const setProcess = (state, { payload }) => ({
  ...state,
  processData: payload,
})

const setIdentifyUser = (state, { payload }) => ({
  ...state,
  identification: payload,
})

const saveProcess = (state) => ({
  ...state,
  save: {
    isLoading: true,
  },
})

const saveProcessSuccess = (state, { response }) => ({
  ...state,
  save: {
    isLoading: false,
    isSuccess: response.success,
  },
  validation: {
    denied: response.result.denied,
    status: response.result.status,
  },
})

const saveProcessFailure = (state, { errors }) => ({
  ...state,
  save: {
    isLoading: false,
    errors,
  },
})

const resetProcess = () => ({
  ...INITIAL_STATE,
})

const resetErrors = (state) => ({
  ...state,
  save: {
    ...INITIAL_STATE.save,
  },
})

const resetBrokerAppointment = (state) => ({
  ...state,
  brokerAppointment: {
    isLoading: false,
    allowBrokerSearch: false,
    data: {},
    errors: [],
  },
})

export default createReducer(INITIAL_STATE, {
  [ProcessTypes.SEARCH_PROCESS]: searchProcess,
  [ProcessTypes.SEARCH_PROCESS_SUCCESS]: searchProcessSuccess,
  [ProcessTypes.SEARCH_PROCESS_FAILURE]: searchProcessFailure,

  [ProcessTypes.SEARCH_BROKER]: searchBroker,
  [ProcessTypes.SEARCH_BROKER_SUCCESS]: searchBrokerSuccess,
  [ProcessTypes.SEARCH_BROKER_FAILURE]: searchBrokerFailure,

  [ProcessTypes.VERIFY_BROKER_APPOINTMENT]: verifyBrokerAppointment,
  [ProcessTypes.VERIFY_BROKER_APPOINTMENT_SUCCESS]:
    verifyBrokerAppointmentSuccess,
  [ProcessTypes.VERIFY_BROKER_APPOINTMENT_FAILURE]:
    verifyBrokerAppointmentFailure,

  [ProcessTypes.SET_STEP]: setStep,
  [ProcessTypes.SET_PROCESS]: setProcess,
  [ProcessTypes.SET_IDENTIFY_USER]: setIdentifyUser,

  [ProcessTypes.SAVE_PROCESS]: saveProcess,
  [ProcessTypes.SAVE_PROCESS_SUCCESS]: saveProcessSuccess,
  [ProcessTypes.SAVE_PROCESS_FAILURE]: saveProcessFailure,

  [ProcessTypes.GET_QUOTE]: getQuote,
  [ProcessTypes.GET_QUOTE_SUCCESS]: getQuoteSuccess,
  [ProcessTypes.GET_QUOTE_FAILURE]: getQuoteFailure,

  [ProcessTypes.UPDATE_QUOTE]: updateQuote,
  [ProcessTypes.UPDATE_QUOTE_SUCCESS]: updateQuoteSuccess,
  [ProcessTypes.UPDATE_QUOTE_FAILURE]: updateQuoteFailure,

  [ProcessTypes.RESET_PROCESS]: resetProcess,
  [ProcessTypes.RESET_ERRORS]: resetErrors,
  [ProcessTypes.RESET_BROKER_APPOINTMENT]: resetBrokerAppointment,
})

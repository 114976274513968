import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Grid,
  Link,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core'

import urls from 'utils/constants/urls'
import cookieImage from 'assets/svg/icon-cookie.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    right: 65,
    bottom: 20,
    left: 65,
    zIndex: 25,
    opacity: 0,
    visibility: 'hidden',
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
    transitionProperty: 'opacity, visibility',
    transitionDuration: 'time(default)',
    transitionTimingFunction: 'ease(inout)',
    [theme.breakpoints.down('lg')]: {
      right: 'unset',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '80%',
    },
  },
  active: {
    opacity: 1,
    visibility: 'visible',
    pointerEvents: 'inherit',
  },
  paper: {
    position: 'relative',
    width: '100%',
    maxWidth: 1200,
    backgroundColor: theme.palette.background.paper,
    padding: 15,
  },
  iconBox: {
    position: 'absolute',
    top: -11,
    left: -11,
  },
  image: {
    content: `url(${cookieImage})`,
    width: 22,
    height: 22,
  },
  text: {
    fontSize: 14,
  },
  link: {
    textDecoration: 'underline',
    marginTop: -3.5,
    cursor: 'pointer',
  },
}))

const CookiePolicy = () => {
  const classes = useStyles()

  const [accepted, setAccepted] = useState(true)

  const loadScripts = () => {
    const body = document.querySelector('body')
    const scripts = document.createElement('script')
    const googleTagManager = document.createElement('script')
    const blipChat = document.createElement('script')

    scripts.src = `${window.PUBLIC_URL}/scripts.js`
    scripts.async = true

    googleTagManager.src = `https://www.googletagmanager.com/gtag/js?id=${window.ENV.GA_CONFIG}`
    googleTagManager.async = true

    blipChat.src = 'https://unpkg.com/blip-chat-widget'
    blipChat.async = true

    document.querySelector('main').classList.add('no-cookie')
    document.body.appendChild(googleTagManager)
    document.body.appendChild(blipChat)
    body.dispatchEvent(new Event('accepted'))
    setTimeout(() => document.body.appendChild(scripts), 2000)
  }

  const acceptCookies = () => {
    let expiryDate = new Date()
    expiryDate.setMonth(expiryDate.getMonth() + 3)

    if (window.innerWidth <= 1024) {
      document.querySelector('#main-cta-whatsapp').style.bottom = '110px'
    }
    document.cookie = `accepted-cookie-policy=true expires=${expiryDate} Path=/`
    setAccepted(true)
    loadScripts()
  }

  useEffect(() => {
    const cookies = document.cookie
    const cookieHeight = document.querySelector('#main-cookie-policy')
      .offsetHeight

    if (cookies.includes('accepted-cookie-policy=true')) {
      if (window.innerWidth <= 1024) {
        document.querySelector('#main-cta-whatsapp').style.bottom = '110px'
      }
      loadScripts()
    } else {
      setAccepted(false)
      if (window.innerWidth <= 1024) {
        document.querySelector('#main-cta-whatsapp').style.bottom =
          cookieHeight + 60 + 'px'
      }
    }
  }, [])

  return (
    <Box className={[classes.root, accepted ? '' : classes.active]} id="main-cookie-policy">
      <Paper className={classes.paper}>
        <Box className={classes.iconBox}>
          <Box className={classes.image} />
        </Box>
        <Grid container alignItems="center" spacing={3}>
          <Grid item md={10} xs={12}>
            <Typography className={classes.text}>
              Nós usamos cookies e outras tecnologias semelhantes para melhorar
              sua experiência, personalizar publicidade e recomendar conteúdo de
              seu interesse. Para continuar navegando, você deve concordar com
              este monitoramento. Para mais informações, consulte a nossa {' '}
              <Link
                component="span"
                className={classes.link}
                title="Ir para a página de política de cookies"
                onClick={() => { window.open(urls.LINKS.cookiePolicy, '_blank') }}
              >
                Política de Cookies
              </Link>
              .
            </Typography>
          </Grid>

          <Grid item md={2} xs={12}>
            <Button
              color="primary"
              variant="contained"
              title="Aceitar"
              fullWidth
              onClick={() => acceptCookies()}
              type="button"
            >
              Aceitar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default CookiePolicy

import React from 'react'
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'

import { history } from 'store'
import urls from 'utils/constants/urls'
import errorImage from 'assets/svg/image-error.svg'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 60,
    fontWeight: 'bold',
    fontFamily: 'var(--font-poppins)',
    textAlign: 'center',
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  text: {
    fontSize: 16,
    textAlign: 'center',
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  image: {
    content: `url(${errorImage})`,
    width: 280,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: 160,
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(2),
      marginRight: 0,
    },
  },
  buttonGrid: {
    minWidth: 260,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

const ErrorFeedback = ({ data }) => {
  const classes = useStyles()

  const handleRedirectToHome = () => {
    history.push(urls.ROUTES.app.path)
  }

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <Box className={classes.image} />
      </Grid>

      <Grid item>
        <Typography component="h1" className={classes.title}>
          Oops!
        </Typography>
      </Grid>

      <Grid item>
        <Typography className={classes.text}>
          Desculpe, parece que algo deu errado...
        </Typography>
      </Grid>

      <Grid item className={classes.buttonGrid}>
        <Button
          color="primary"
          variant="contained"
          title="Voltar à página inicial"
          fullWidth
          onClick={handleRedirectToHome}
        >
          Voltar à página inicial
        </Button>
      </Grid>
    </Grid>
  )
}

export default ErrorFeedback

import * as Yup from 'yup'
import { isCpf, isCnpj, isCep } from 'validator-brazil'

import YUP_MESSAGE from 'helpers/yup'
import { PHONE_MASKS } from 'components/PhoneInput'
import useUtils from './useUtils'

const useYup = () => {
  const { isBlank, getOnlyNumber } = useUtils()

  const cpf = Yup.string().test({
    name: 'cpf',
    message: 'O CPF não é válido.',
    test: (value) => {
      let valid = true

      if (!isBlank(value)) {
        valid = isCpf(value)
      }
      return valid
    },
  })

  const cep = Yup.string().test({
    name: 'cep',
    message: 'O CEP não é válido.',
    test: (value) => {
      let valid = true

      if (!isBlank(value)) {
        valid = isCep(value.trim())
      }
      return valid
    },
  })

  const cnpj = Yup.string().test({
    name: 'cnpj',
    message: 'O CNPJ não é válido.',
    test: (value) => {
      let valid = true

      if (!isBlank(value)) {
        valid = isCnpj(value)
      }
      return valid
    },
  })

  const cpfOrCnpj = Yup.string().test({
    name: 'cpfOrCnpj',
    message: 'O CPF/CNPJ não é válido.',
    test: (value) => {
      let valid = true

      if (!isBlank(value)) {
        valid = isCpf(value) || isCnpj(value)
      }
      return valid
    },
  })

  const phone = Yup.string().test({
    name: 'phone',
    message: 'Telefone inválido.',
    test: (value) => {
      let valid = true

      if (!isBlank(value)) {
        valid = value.length === PHONE_MASKS.phone.length
      }
      return valid
    },
  })

  const cellphone = Yup.string().test({
    name: 'cellphone',
    message: 'Celular inválido.',
    test: (value) => {
      let valid = true

      if (!isBlank(value)) {
        valid = value.length === PHONE_MASKS.cellphone.length
      }
      return valid
    },
  })

  const phoneOrCellphone = Yup.string().test({
    name: 'phoneOrCellphone',
    message: 'Telefone/Celular inválido.',
    test: (value) => {
      let valid = true

      if (!isBlank(value)) {
        const number = getOnlyNumber(value)
        const phoneCount = getOnlyNumber(PHONE_MASKS.phone).length
        const cellphoneCount = getOnlyNumber(PHONE_MASKS.cellphone).length
        valid = number.trim().length === phoneCount || number.trim().length === cellphoneCount
      }
      return valid
    },
  })

  const onlyNumber = Yup.string().test({
    name: 'onlyNumber',
    message: YUP_MESSAGE.invalid,
    test: (value) => {
      let valid = true

      if (!isBlank(value)) {
        const digits = getOnlyNumber(value)
        valid = digits.length === value.length
      }
      return valid
    },
  })

  return {
    cpf,
    cep,
    cnpj,
    cpfOrCnpj,
    phone,
    cellphone,
    phoneOrCellphone,
    onlyNumber,
  }
}

export default useYup

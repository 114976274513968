import { useSelector } from 'react-redux'

export default () => {
  const {
    complainant: { data: complainantData },
    defendant: { data: defendantData },
  } = useSelector((state) => state.people)

  return { complainantData, defendantData }
}

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'
import { TextField } from '@material-ui/core'

const ProcessNumberInput = (props) => {
  const { id, onChange } = props

  const handleChange = (event) => {
    const { value } = event.target

    onChange({
      target: { id, value },
    })
  }

  const field = useMemo(() => {
    const inputProps = { ...props }
    delete inputProps.onBlur
    delete inputProps.onChange

    return <TextField {...inputProps} />
  }, [props])

  return (
    <InputMask
      {...props}
      maskChar={null}
      onChange={handleChange}
      mask="9999999-99.9999.9.99.9999"
    >
      {() => field}
    </InputMask>
  )
}

ProcessNumberInput.propTypes = {
  id: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

ProcessNumberInput.defaultProps = {
  onBlur: () => { },
  onChange: () => { },
}

export default ProcessNumberInput

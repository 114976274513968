import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from './auth'
import resource from './resource'
import people from './people'
import address from './address'
import process from './process'
import proposal from './proposal'
import policy from './policy'

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    resource,
    people,
    address,
    process,
    proposal,
    policy,
  })

const Reducers = (history) => appReducer(history)

export default Reducers

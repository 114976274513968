import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Typography,
  withStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'

import useUtils from 'hooks/useUtils'
import { ReactComponent as IconDownload } from 'assets/svg/icon-download.svg'
import AutoRenovation from 'assets/svg/proposal-automatic-renovation.svg'
import Cancellation from 'assets/svg/proposal-cancellation.svg'
import Validity from 'assets/svg/proposal-validity.svg'
import { API } from 'services/api'
import { GET_MINUTA_DOCUMENT } from 'utils/constants'
import { HTML_LOADING } from 'utils/constants/loading'
import { getProposalData } from 'helpers/localStorage'

const Accordion = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      marginTop: theme.spacing(2),
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
    },
  },
  expanded: {},
}))(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    padding: 0,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails)

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    minHeight: 605,
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    marginBottom: 10,
    padding: 24,
  },
  topTitle: {
    fontSize: 12,
    fontFamily: 'var(--font-poppins)',
    fontWeight: '600',
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.main,
    textTransform: 'upperCase',
  },
  titleLine: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    width: 30,
    height: 3,
    background: theme.palette.primary.main,
  },
  mainTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'var(--font-poppins)',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  dataTitle: {
    fontWeight: 600,
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'var(--font-poppins)',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dataItemGrid: {
    marginBottom: theme.spacing(2),
  },
  accordionTitle: {
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'var(--font-poppins)',
  },
  accordionItemTitle: {
    fontSize: 16,
    fontWeight: 600,
  },
  accordionItemText: {
    fontSize: 16,
    fontWeight: 'normal',
    marginTop: theme.spacing(1),
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'var(--font-poppins)',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  semiBold: {
    fontWeight: 600,
  },
  instructionText: {
    fontSize: 16,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  downloadButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 8,
    maxWidth: 300,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      maxWidth: '100%',
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
  },
  iconDownload: {
    width: 20,
    marginRight: 10,
    marginTop: -10,
    marginBottom: -10,
  },
  autoRenovation: {
    content: `url(${AutoRenovation})`,
  },
  cancellation: {
    content: `url(${Cancellation})`,
  },
  validity: {
    content: `url(${Validity})`,
  },
}))

const ProcessSection = ({ proposal }) => {
  const classes = useStyles()
  const { formatCNPJ, formatCPFOrCNPJ } = useUtils()
  const [minutaDocumentResult, setMinutaDocumentResult] = useState()

  const policyHolder = proposal.participants.find(
    (p) => p.role === 'PolicyHolder'
  )
  const insured = proposal.participants.find((p) => p.role === 'Insured')

  const handleDownloadMinutaDocument = async () => {
    const { quoteId } = getProposalData()
    const w = window.open('', '_blank')

    w.document.write(HTML_LOADING)
    w.document.close()
    let result

    if (minutaDocumentResult) result = minutaDocumentResult
    else {
      result = await API.get(GET_MINUTA_DOCUMENT(quoteId))
      setMinutaDocumentResult(result)
    }

    w.document.title = result.data.fileDownloadName
    w.document.write(
      `<iframe width='100%' height='100%' src='data:application/pdf;base64, ${encodeURI(
        result.data.fileContents
      )}'></iframe>`
    )
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Typography className={classes.topTitle}>
          Pottencial Garantia Recursal
        </Typography>

        <Grid container direction="row" alignItems="center">
          <Grid item lg={6} xs={12}>
            <Typography component="h1" className={classes.mainTitle}>
              Proposta n°{' '}
              <span className={classes.colorPrimary}>
                {proposal.proposalNumber}
              </span>
            </Typography>

            <span className={classes.titleLine} />
          </Grid>
          <Grid item lg={6} xs={12} className={classes.downloadButton}>
            <Button
              color="primary"
              variant="outlined"
              title="Download da proposta"
              onClick={() => {
                window.open(proposal.documentUrl)
              }}
              startIcon={<IconDownload className={classes.iconDownload} />}
            >
              Download da proposta
            </Button>

            <Button
              color="primary"
              variant="outlined"
              title="Download da minuta"
              onClick={handleDownloadMinutaDocument}
              startIcon={<IconDownload className={classes.iconDownload} />}
            >
              Download da minuta
            </Button>
          </Grid>
        </Grid>

        <Typography component="h1" className={classes.title}>
          Informações do seguro
        </Typography>

        <Grid container>
          <Grid item xs={12}>
            <Typography component="h1" className={classes.subTitle}>
              Dados do tomador:
            </Typography>
          </Grid>

          <Grid item lg={5} xs={12} className={classes.dataItemGrid}>
            <Typography variant="body1" className={classes.dataTitle}>
              Nome:
            </Typography>
            <Typography variant="body1">
              {policyHolder?.name}
              &nbsp;
            </Typography>
          </Grid>

          <Grid item lg={7} xs={12} className={classes.dataItemGrid}>
            <Typography variant="body1" className={classes.dataTitle}>
              CNPJ do tomador:
            </Typography>
            <Typography variant="body1">
              {formatCNPJ(policyHolder?.documentNumber)}
              &nbsp;
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography component="h1" className={classes.subTitle}>
              Dados da corretora:
            </Typography>
          </Grid>

          <Grid item lg={5} xs={12} className={classes.dataItemGrid}>
            <Typography variant="body1" className={classes.dataTitle}>
              Nome:
            </Typography>
            <Typography variant="body1">
              {proposal?.brokerName}
              &nbsp;
            </Typography>
          </Grid>

          <Grid item lg={7} xs={12} className={classes.dataItemGrid}>
            <Typography variant="body1" className={classes.dataTitle}>
              CNPJ da corretora:
            </Typography>
            <Typography variant="body1">
              {formatCNPJ(proposal?.brokerDocumentNumber)}
              &nbsp;
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography component="h1" className={classes.subTitle}>
              Dados do processo:
            </Typography>
          </Grid>

          <Grid item lg={5} xs={12} className={classes.dataItemGrid}>
            <Typography variant="body1" className={classes.dataTitle}>
              Número do processo:
            </Typography>
            <Typography variant="body1">
              {proposal.processNumber}
              &nbsp;
            </Typography>
          </Grid>

          <Grid item lg={4} xs={12} className={classes.dataItemGrid}>
            <Typography variant="body1" className={classes.dataTitle}>
              Vara e comarca do processo:
            </Typography>
            <Typography variant="body1">
              {proposal.comarcaNumber}
              &nbsp;
            </Typography>
          </Grid>

          <Grid item lg={3} xs={12} className={classes.dataItemGrid}>
            <Typography variant="body1" className={classes.dataTitle}>
              Tipo de recurso:
            </Typography>
            <Typography variant="body1">
              {proposal.resourceType}
              &nbsp;
            </Typography>
          </Grid>

          <Grid item lg={5} xs={12} className={classes.dataItemGrid}>
            <Typography variant="body1" className={classes.dataTitle}>
              Nome do reclamante:
            </Typography>
            <Typography variant="body1">
              {insured?.name}
              &nbsp;
            </Typography>
          </Grid>

          <Grid item lg={4} xs={12} className={classes.dataItemGrid}>
            <Typography variant="body1" className={classes.dataTitle}>
              CPF ou CNPJ do reclamante:
            </Typography>
            <Typography variant="body1">
              {formatCPFOrCNPJ(insured?.documentNumber)}
              &nbsp;
            </Typography>
          </Grid>
        </Grid>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="info-accordion-content"
            id="info-accordion-header"
          >
            <Typography component="h1" className={classes.accordionTitle}>
              Mais informações
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Hidden xsDown>
                    <Grid item xs="auto">
                      <span className={classes.cancellation} />
                    </Grid>
                  </Hidden>

                  <Grid item xs>
                    <Typography
                      component="h1"
                      className={classes.accordionItemTitle}
                    >
                      Cancelamento
                    </Typography>

                    <Typography className={classes.accordionItemText}>
                      Os cancelamentos com restituição de prêmio estão previstos
                      <span className={classes.semiBold}> apenas </span>
                      nos seguintes casos: indeferimento do juízo ou emissão em
                      duplicidade por erro humano.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Hidden xsDown>
                    <Grid item xs="auto">
                      <span className={classes.validity} />
                    </Grid>
                  </Hidden>

                  <Grid item xs>
                    <Typography
                      component="h1"
                      className={classes.accordionItemTitle}
                    >
                      Validade
                    </Typography>

                    <Typography className={classes.accordionItemText}>
                      Você paga apenas uma vez e a apólice (contrato) cobrirá o
                      seguro recursal por
                      <span className={classes.semiBold}>
                        {' '}
                        {proposal.yearsValid} anos
                      </span>
                      .
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Hidden xsDown>
                    <Grid item xs="auto">
                      <span className={classes.autoRenovation} />
                    </Grid>
                  </Hidden>

                  <Grid item xs>
                    <Typography
                      component="h1"
                      className={classes.accordionItemTitle}
                    >
                      Renovação automática
                    </Typography>

                    <Typography className={classes.accordionItemText}>
                      Você não precisa se preocupar com o vencimento da apólice
                      se o processo ainda não tiver encerrado. A renovação da
                      apólice ocorrerá de forma automática, quando
                      <span className={classes.semiBold}> faltar 60 dias </span>
                      para o seu vencimento.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </>
  )
}

ProcessSection.propTypes = {
  proposal: PropTypes.object.isRequired,
}

export default ProcessSection

import { history } from 'store'
import urls from 'utils/constants/urls'
import { ACCESS_TOKEN } from 'utils/constants/constants'
import userManager from './userManager'

class AuthService {
  signinRedirectCallback = () => {
    userManager
      .signinRedirectCallback()
      .then(() => {
        history.push(urls.ROUTES.simulator.path)
      })
      .catch((err) => {})
  }

  signinSilentCallback = () => {
    userManager.signinSilentCallback()
  }

  signoutRedirectCallback = () => {
    userManager.signoutRedirectCallback().then(() => {
      localStorage.clear()
      history.push(urls.ROUTES.app.path)
    })
    userManager.clearStaleState()
  }

  getUser = async () => {
    const user = await userManager.getUser()
    return user
  }

  isAuthenticated = () => {
    const key = ACCESS_TOKEN
    let oidcStorage

    if (localStorage.hasOwnProperty(key)) {
      oidcStorage = JSON.parse(localStorage.getItem(key))
    }

    return !!oidcStorage && !!oidcStorage.access_token
  }
}

export const authService = new AuthService()

import styled from 'styled-components'
import media from 'styled-media-query'

import { ReactComponent as IcWhatsapp } from 'assets/svg/icon-whatsapp.svg'

export const WhatsApp = styled.div`
  position: fixed;
  right: 35px;
  bottom: 120px;
  z-index: 30;

  ${media.lessThan('large')`
    bottom: 110px;
  `}

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 2px solid var(--green);
    border-radius: 50%;
    transition-property: border-color;
    transition-duration: time(fast);
    transition-timing-function: ease(inout);
    animation-name: waves_whatsapp;
    animation-duration: 1.5s;
    animation-timing-function: ease(inout);
    animation-iteration-count: infinite;
    z-index: 1;
  }

  &::after {
    animation-delay: 0.5s;
  }

  &:hover {
    &::before,
    &::after {
      border-color: lighten(var(--green), 10%);
    }

    a {
      background-color: lighten(var(--green), 10%);
      box-shadow: 0 5px 50px fade-out(black, 0.8);
    }
  }
`

export const IconWhatsApp = styled(IcWhatsapp)`
  width: 35px;

  ${media.lessThan('large')`
    width: 30px;
  `}
`

export const WhatsAppLink = styled.a`
  background-color: var(--green);
  width: 58px;
  height: 58px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition-property: background-color, box-shadow;
  transition-duration: time(fast);
  transition-timing-function: ease(inout);
  z-index: 2;

  ${media.lessThan('large')`
    width: 48px;
    height: 48px;
  `}
`

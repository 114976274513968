import { createActions, createReducer } from 'reduxsauce'

export const {
  Types: ResourceTypes,
  Creators: ResourceActions,
} = createActions({
  getResource: ['payload'],
  getResourceSuccess: ['response'],
  getResourceFailure: ['errors'],

  getValidityOptions: ['payload'],
  getValidityOptionsSuccess: ['response'],
  getValidityOptionsFailure: ['errors'],
})

export const INITIAL_STATE = {
  isLoading: false,
  isSuccess: false,
  data: [],
  errors: [],

  validity: {
    isLoading: false,
    isSuccess: false,
    data: [],
    errors: [],
  },

  resourceType: {
    isLoading: false,
    isSuccess: false,
    data: {},
    errors: [],
  },
}

const getResource = (state) => ({
  ...state,
  isLoading: true,
})

const getResourceSuccess = (state, { response }) => ({
  ...state,
  isLoading: false,
  data: response.result,
  isSuccess: response.success,
})

const getResourceFailure = (state, { errors }) => ({
  ...state,
  isLoading: false,
  isSuccess: false,
  errors,
})

const getValidityOptions = (state) => ({
  ...state,
  validity: {
    isLoading: true,
  },
})

const getValidityOptionsSuccess = (state, { response }) => ({
  ...state,
  validity: {
    isLoading: false,
    data: response.result,
    isSuccess: response.success,
  },
})

const getValidityOptionsFailure = (state, { errors }) => ({
  ...state,
  validity: {
    isLoading: false,
    isSuccess: false,
    errors,
  },
})

export default createReducer(INITIAL_STATE, {
  [ResourceTypes.GET_RESOURCE]: getResource,
  [ResourceTypes.GET_RESOURCE_SUCCESS]: getResourceSuccess,
  [ResourceTypes.GET_RESOURCE_FAILURE]: getResourceFailure,

  [ResourceTypes.GET_VALIDITY_OPTIONS]: getValidityOptions,
  [ResourceTypes.GET_VALIDITY_OPTIONS_SUCCESS]: getValidityOptionsSuccess,
  [ResourceTypes.GET_VALIDITY_OPTIONS_FAILURE]: getValidityOptionsFailure,
})

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { isEmpty } from 'lodash'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Typography,
  TextField,
  Box,
} from '@material-ui/core'
import loading from 'assets/loading/loading.json'

import useYup from 'hooks/useYup'
import { AutocompleteInput, CNPJInput, ProcessNumberInput } from 'components'
import { Loading } from 'components/core'
import { API } from 'services/api'
import { GET_MINUTA_DOCUMENT } from 'utils/constants'
import { HTML_LOADING } from 'utils/constants/loading'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: 10,
    padding: 24,
  },
  topTitle: {
    fontSize: 12,
    fontFamily: 'var(--font-poppins)',
    fontWeight: '600',
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.main,
    textTransform: 'upperCase',
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'var(--font-poppins)',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  brokerTitle: {
    marginTop: `calc(${theme.spacing(2)}px * 0.875)`,
  },
  defendantField: {
    height: `calc(${theme.spacing(1)}px * 8.875)`,
  },
  asterisc: {
    fontWeight: 'normal',
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: 400,
  },
  formControlRadio: {
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: 350,
  },
  radioGroup: {
    '& .MuiFormControlLabel-label': {
      fontSize: '0.9em',
      color: theme.palette.text.primary,
    },
  },
  buttons: {
    marginTop: theme.spacing(3),
  },
}))

const SearchProcess = ({
  onSearchProcessNumber,
  handleIdentify,
  isLoading,
  handleSearchBroker,
  brokerIsLoading,
  brokerOptions,
  handleVerifyBrokerAppointment,
  verifyAppointmentIsLoading,
  allowBrokerSearch,
  appointmentData,
  handleResetBrokerAppointment,
  resourceIsLoading,
  resourceData,
  saveProcessData,
  searchProcessResponseData,
  searchErrors,
  processData,
}) => {
  const classes = useStyles()
  const { cnpj } = useYup()
  const formik = useFormik({
    validateOnMount: false,
    initialValues: {
      ...{
        processNumber: '',
        persona: '',
        processComarca: '',
        resourceType: '',
        defendantDocument: '',
        brokerName: '',
        processVara: '',
      },
    },
    validationSchema: Yup.object().shape({
      processNumber: Yup.string()
        .required('Digite o número do processo.')
        .min(25, 'O número do processo não é válido.'),
      persona: Yup.string()
        .oneOf(['lawyer', 'entrepreneur', 'other'], 'Selecione uma das opções.')
        .required('Selecione uma das opções.'),
      resourceType: Yup.string().required('Selecione um tipo de Recurso.'),
      defendantDocument: cnpj.required('O CNPJ é necessário.'),
      brokerName: Yup.object(),
    }),
    onSubmit: (formData) => {
      const data = {
        process: {
          number: formData.processNumber,
          resourceType: formData.resourceType,
          comarca: `${formData.processComarca}, ${formData.processVara}`,
        },
        defendant: {
          document: formData.defendantDocument,
        },
        persona: formData.persona,
        broker: formData.brokerName,
      }
      saveProcessData({ ...data })
    },
  })
  const { onChange: onChangeProcessNumberFormik, ...restProcessNumberProps } =
    formik.getFieldProps('processNumber')

  const [showProcessDataFields, setShowProcessDataFields] = useState(false)

  useEffect(() => {
    if (!isEmpty(processData)) {
      const { process, defendant, persona, broker } = processData

      formik.setFieldValue('processNumber', process.number)
      formik.setFieldValue('persona', persona)
      formik.setFieldValue('resourceType', process.resourceType)
      formik.setFieldValue('defendantDocument', defendant.document)
      formik.setFieldValue('brokerName', broker)
    }
  }, [processData])

  const callHandleIdentify = (event) => {
    const { value } = event.target

    if (!isEmpty(value)) {
      handleIdentify({ persona: value })
    }
  }

  const onChangeProcessInput = (event) => {
    onChangeProcessNumberFormik(event)

    const processNumberValue = event.target.value

    if (!isEmpty(processNumberValue) && processNumberValue.length === 25) {
      onSearchProcessNumber(processNumberValue)
      formik.submitForm()
    }
  }

  useEffect(() => {
    if (searchErrors) {
      setShowProcessDataFields(false)

      const validationError = searchErrors.find((error) =>
        error.Message.includes('is not valid')
      )

      if (validationError) {
        formik.setFieldError(
          'processNumber',
          'O número do processo informado não é válido.'
        )
      }
    }
  }, [searchErrors])

  useEffect(() => {
    if (
      (isEmpty(formik.errors.processNumber) || !formik.touched.processNumber) &&
      searchProcessResponseData
    ) {
      setShowProcessDataFields(true)

      formik.setFieldValue(
        'processComarca',
        searchProcessResponseData.name || ''
      )
      formik.setFieldValue(
        'processVara',
        searchProcessResponseData.rods?.[0]?.name || ''
      )
    } else {
      setShowProcessDataFields(false)
      formik.setFieldValue('resourceType', '')
      formik.setFieldValue('defendantDocument', '')
      formik.setFieldValue('brokerName', '')
    }
  }, [
    searchProcessResponseData,
    formik.errors.processNumber,
    formik.touched.processNumber,
  ])

  useEffect(() => {
    const { value: defendantDocumentValue } =
      formik.getFieldProps('defendantDocument')

    if (
      !formik.errors.defendantDocument &&
      defendantDocumentValue?.length === 14
    ) {
      handleVerifyBrokerAppointment(defendantDocumentValue)
    } else {
      handleResetBrokerAppointment()
      formik.setFieldValue('brokerName', '')
    }
  }, [
    formik.getFieldProps('defendantDocument').value,
    formik.errors.defendantDocument,
  ])

  useEffect(() => {
    if (appointmentData?.brokerName) {
      formik.setFieldValue('brokerName', {
        name: appointmentData.brokerName,
        documentNumber: appointmentData.brokerDocument,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentData])

  const handleBackButtonClick = () => {
    window.location.assign(window.ENV.GARANTIA_DIGITAL)
  }

  const handleSubmitForm = () => {
    formik.submitForm()
  }

  return (
    <>
      <form className={classes.root}>
        <Paper className={classes.paper}>
          <Typography className={classes.topTitle}>
            Pottencial Garantia Recursal
          </Typography>

          <Typography component="h1" className={classes.title}>
            Qual é o seu papel?<span className={classes.asterisc}>*</span>
          </Typography>

          <FormControl
            fullWidth
            className={classes.formControlRadio}
            error={formik.touched.persona && !!formik.errors.persona}
          >
            <RadioGroup
              row
              id="persona"
              className={classes.radioGroup}
              {...formik.getFieldProps('persona')}
            >
              <FormControlLabel
                value="lawyer"
                label="Advogado"
                title="Advogado"
                onClick={callHandleIdentify}
                control={<Radio color="primary" />}
              />
              <FormControlLabel
                value="entrepreneur"
                label="Empregador"
                title="Empregador"
                onClick={callHandleIdentify}
                control={<Radio color="primary" />}
              />
              <FormControlLabel
                value="other"
                label="Outro"
                title="Outro"
                onClick={callHandleIdentify}
                control={<Radio color="primary" />}
              />
            </RadioGroup>
            <FormHelperText>
              {formik.touched.persona && formik.errors.persona}
            </FormHelperText>
          </FormControl>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} direction="column">
              <Typography component="h1" className={classes.title}>
                Dados do processo:
              </Typography>

              <Box width="100%">
                <FormControl className={classes.formControl}>
                  <ProcessNumberInput
                    id="processNumber"
                    color="secondary"
                    label="Número do Processo*"
                    title="Número do Processo"
                    fullWidth
                    {...restProcessNumberProps}
                    onChange={onChangeProcessInput}
                    error={
                      formik.touched.processNumber &&
                      !!formik.errors.processNumber
                    }
                    helperText={
                      formik.touched.processNumber &&
                      formik.errors.processNumber
                    }
                  />
                  <Loading isLoading={isLoading} isInput />
                </FormControl>
                {showProcessDataFields && (
                  <>
                    <TextField
                      id="processComarca"
                      color="secondary"
                      label="Tribunal e região da Justiça do Trabalho"
                      title="Tribunal e região da Justiça do Trabalho"
                      fullWidth
                      {...formik.getFieldProps('processComarca')}
                      disabled
                      error={
                        formik.touched.processComarca &&
                        !!formik.errors.processComarca
                      }
                      helperText={
                        formik.touched.processComarca &&
                        formik.errors.processComarca
                      }
                      className={classes.formControl}
                    />

                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Vara
                      </InputLabel>
                      <Select
                        label="Vara"
                        value={searchProcessResponseData?.rods?.[0].name}
                        {...formik.getFieldProps('processVara')}
                      >
                        {searchProcessResponseData?.rods?.map((rod) => (
                          <MenuItem value={rod.name} key={rod.id}>
                            {rod.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      className={classes.formControl}
                      error={
                        formik.touched.resourceType &&
                        !!formik.errors.resourceType
                      }
                    >
                      <InputLabel color="secondary">
                        Tipo de recurso*
                      </InputLabel>
                      <Select
                        id="resourceType"
                        color="secondary"
                        fullWidth
                        {...formik.getFieldProps('resourceType')}
                        renderValue={(value) => value?.description ?? ''}
                      >
                        {resourceData.map((type) => (
                          <MenuItem key={type.id} value={type}>
                            {type.description}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {formik.touched.resourceType &&
                          formik.errors.resourceType}
                      </FormHelperText>
                    </FormControl>
                  </>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              {showProcessDataFields && (
                <>
                  <Typography component="h1" className={classes.title}>
                    Dados do Tomador:
                  </Typography>

                  <FormControl
                    className={clsx(
                      classes.formControl,
                      classes.defendantField
                    )}
                  >
                    <CNPJInput
                      id="defendantDocument"
                      color="secondary"
                      label="CNPJ do tomador*"
                      title="CNPJ do tomador"
                      fullWidth
                      {...formik.getFieldProps('defendantDocument')}
                      error={!!formik.errors.defendantDocument}
                      helperText={
                        formik.touched.defendantDocument &&
                        formik.errors.defendantDocument
                      }
                    />
                  </FormControl>

                  <Typography
                    component="h1"
                    className={clsx(classes.title, classes.brokerTitle)}
                  >
                    Informe seu corretor:
                  </Typography>

                  <FormControl className={classes.formControl}>
                    <AutocompleteInput
                      id="brokerName"
                      color="secondary"
                      label="Nome da Corretora*"
                      title="Nome da Corretora"
                      fullWidth
                      disabled={!allowBrokerSearch}
                      minSize={3}
                      options={brokerOptions}
                      optionLabel={(option) =>
                        `${option.name} - ${option.documentNumber}`
                      }
                      searchOptions={handleSearchBroker}
                      isLoading={brokerIsLoading}
                      {...formik.getFieldProps('brokerName')}
                      error={!!formik.errors.brokerName}
                      helperText={
                        formik.touched.brokerName && formik.errors.brokerName
                      }
                    />
                  </FormControl>
                </>
              )}
            </Grid>
          </Grid>
        </Paper>

        <Grid
          pb={2}
          container
          justify="flex-end"
          spacing={2}
          className={classes.buttons}
        >
          <Grid item lg={3} sm={4} xs={12}>
            <Button
              color="primary"
              variant="outlined"
              title="Voltar"
              fullWidth
              onClick={handleBackButtonClick}
            >
              Voltar
            </Button>
          </Grid>

          <Grid item lg={3} sm={4} xs={12}>
            <Button
              color="primary"
              variant="contained"
              title="Próximo"
              fullWidth
              onClick={handleSubmitForm}
              disabled={!formik.isValid}
            >
              Próximo
            </Button>
          </Grid>
        </Grid>
      </form>

      <Loading isLoading={verifyAppointmentIsLoading || resourceIsLoading} />
    </>
  )
}

SearchProcess.propTypes = {
  onSearchProcessNumber: PropTypes.func.isRequired,
  handleIdentify: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  handleSearchBroker: PropTypes.func.isRequired,
  brokerIsLoading: PropTypes.bool,
  brokerOptions: PropTypes.array,
  handleVerifyBrokerAppointment: PropTypes.func.isRequired,
  verifyAppointmentIsLoading: PropTypes.bool,
  allowBrokerSearch: PropTypes.bool.isRequired,
  appointmentData: PropTypes.object,
  handleResetBrokerAppointment: PropTypes.func.isRequired,
  resourceIsLoading: PropTypes.bool,
  resourceData: PropTypes.array,
  saveProcessData: PropTypes.func.isRequired,
  searchErrors: PropTypes.array,
  processData: PropTypes.array,
  searchProcessResponseData: PropTypes.objectOf({
    rods: PropTypes.arrayOf({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
}

SearchProcess.defaultProps = {
  isLoading: false,
  brokerIsLoading: false,
  brokerOptions: [],
  appointmentData: {},
  resourceIsLoading: false,
  resourceData: [],
  searchErrors: [],
  processData: [],
  searchProcessResponseData: null,
}

export default SearchProcess

import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { isCep, isCnpj, isCpf } from 'validator-brazil'
import {
  Box,
  Button,
  FormControl,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'

import useYup from 'hooks/useYup'
import useUtils from 'hooks/useUtils'
import { history } from 'store'
import urls from 'utils/constants/urls'
import userManager from 'utils/auth/userManager'
import { CEPInput, CPFOrCNPJInput, PictureModal } from 'components'
import {
  Loading,
  MessageHandler,
  Tooltip,
} from 'components/core'
import registerAddressImage from 'assets/images/image-complainant.png'
import modalImageSuccess from 'assets/images/image-quote-approved.jpg'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    marginBottom: 10,
    padding: 24,
  },
  topTitle: {
    fontSize: 12,
    fontFamily: 'var(--font-poppins)',
    fontWeight: '600',
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.main,
    textTransform: 'upperCase',
  },
  mainTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'var(--font-poppins)',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'var(--font-poppins)',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  instructionText: {
    fontSize: 16,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  imageGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  image: {
    content: `url(${registerAddressImage})`,
    width: 247,
    height: 200,
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      width: 164,
      height: 133,
      marginTop: theme.spacing(4),
      marginRight: 0,
    },
  },
  buttons: {
    marginTop: theme.spacing(3),
  },
}))

const RegisterAddress = ({
  complainantDataIsLoading,
  complainantData,
  complainantName,
  complainantDataIsSuccess,
  getComplainant,
  getComplainantAddress,
  resetComplainant,
  complainantAddressIsLoading,
  complainantAddressIsSuccess,
  complainantAddressData,
  updateComplainantAddress,
  defendantDocument,
  isLoading,
  processIsLoading,
  processNumber,
  saveErrors,
  approved,
  ignoreApproval,
}) => {
  const classes = useStyles()
  const { getOnlyNumber } = useUtils()
  const { cep, cpfOrCnpj } = useYup()

  const [hasNumberError, setHasNumberError] = useState(false)

  const formik = useFormik({
    initialValues: {
      ...{
        document: '',
        name: '',
        zipCode: '',
        street: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        state: '',
      },
    },
    validationSchema: Yup.object().shape({
      document: cpfOrCnpj.required('O CPF/CNPJ é necessário.'),
      zipCode: cep.required('O CEP é necessário.'),
      street: Yup.string().required('A rua/av é necessária.'),
      number: Yup.number().required('O número é necessário.'),
      district: Yup.string().required('O bairro é necessário.'),
      city: Yup.string().required('A cidade é necessária.'),
      state: Yup.string().required('O estado é necessário.'),
    }),
    onSubmit: (formData) => {
      const data = {
        complainant: {
          id: formData.id,
          document: formData.document,
          name: complainantData.name,
          zipCode: formData.zipCode,
          street: formData.street,
          number: formData.number,
          complement: formData.complement,
          district: formData.district,
          city: formData.city,
          state: formData.state,
        },
      }

      updateComplainantAddress(data)
    },
  })

  const [openModalSuccess, setOpenModalSuccess] = useState(false)

  const [isAddressSuccess, setIsAddressSuccess] = useState(false)
  const [cityDisabled, setCityDisabled] = useState(true)
  const [stateDisabled, setStateDisabled] = useState(true)
  const [documentError, setDocumentError] = useState(null)

  useEffect(() => {
    if (complainantDataIsSuccess) {
      const { address, document } = complainantData

      setIsAddressSuccess(!isEmpty(address?.zipCode))

      formik.setFieldValue('document', document)
      formik.setFieldValue('zipCode', address?.zipCode)
      formik.setFieldValue('id', address?.id)
      formik.setFieldValue('street', address?.street)
      formik.setFieldValue('number', getOnlyNumber(address?.number))
      formik.setFieldValue('complement', address?.complement)
      formik.setFieldValue('district', address?.district)
      formik.setFieldValue('city', address?.city)
      formik.setFieldValue('state', address?.state)

      setCityDisabled(!isEmpty(address?.city))
      setStateDisabled(!isEmpty(address?.state))

      setTimeout(() => formik.validateForm())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complainantData, complainantDataIsSuccess])

  useEffect(() => {
    if (complainantAddressIsSuccess) {
      const address = complainantAddressData

      setIsAddressSuccess(!isEmpty(address?.zipCode))

      formik.setFieldValue('zipCode', address?.zipCode)
      formik.setFieldValue('id', address?.id)
      formik.setFieldValue('street', address?.street)
      formik.setFieldValue('number', getOnlyNumber(address?.number))
      formik.setFieldValue('complement', address?.complement)
      formik.setFieldValue('district', address?.district)
      formik.setFieldValue('city', address?.city)
      formik.setFieldValue('state', address?.state)

      setCityDisabled(!isEmpty(address?.city))
      setStateDisabled(!isEmpty(address?.state))

      setTimeout(() => formik.validateForm())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complainantAddressData, complainantAddressIsSuccess])

  useEffect(() => {
    if (complainantDataIsSuccess) {
      formik.setFieldValue('name', complainantName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complainantDataIsSuccess, complainantName])

  useEffect(() => {
    if (approved) {
      setOpenModalSuccess(true)
    }
  }, [approved])

  const handleCoseModal = () => {
    setOpenModalSuccess(false)
    ignoreApproval()
  }

  const handleRedirectSimulator = () => {
    history.push({
      pathname: urls.ROUTES.register.path,
      state: processNumber,
    })
  }

  const handleOnChangeDocument = (event) => {
    setDocumentError(null)
    const { value: document } = event.target

    formik.setFieldValue('document', document)

    if (complainantDataIsSuccess) {
      resetComplainant()

      formik.setFieldValue('id', '', true)
      formik.setFieldValue('zipCode', '', true)
      formik.setFieldValue('street', '', true)
      formik.setFieldValue('number', '', true)
      formik.setFieldValue('complement', '', true)
      formik.setFieldValue('district', '', true)
      formik.setFieldValue('city', '', true)
      formik.setFieldValue('state', '', true)
    } else if (document && defendantDocument === getOnlyNumber(document)) {
      setDocumentError('O CPF/CNPJ deve ser diferente do CNPJ do Tomador.')
    } else if (
      document &&
      (isCpf(getOnlyNumber(document)) || isCnpj(getOnlyNumber(document)))
    ) {
      getComplainant(document)
    }
  }

  const handleOnChangeZipCode = (event) => {
    const { value: zipCode } = event.target

    formik.setFieldValue('zipCode', zipCode)

    if (isAddressSuccess) {
      setIsAddressSuccess(false)

      formik.setFieldValue('street', '', true)
      formik.setFieldValue('number', '', true)
      formik.setFieldValue('complement', '', true)
      formik.setFieldValue('district', '', true)
      formik.setFieldValue('city', '', true)
      formik.setFieldValue('state', '', true)
    } else if (zipCode && isCep(getOnlyNumber(zipCode))) {
      getComplainantAddress(zipCode)
    }
  }

  const handleBackButtonClick = () => {
    history.push({
      pathname: urls.ROUTES.app.path,
      dontReset: true,
    })
  }

  const handleOnNumberChange = (event) => {
    const { value } = event.target
    const onlyNumbers = getOnlyNumber(value)

    if (value.length > 0 && onlyNumbers.length === 0) {
      setHasNumberError(true)
    } else {
      setHasNumberError(false)
    }

    formik.setFieldValue('number', getOnlyNumber(value).slice(0, 50))
  }

  const handleOnStateChange = (event) => {
    const { value } = event.target
    formik.setFieldValue('state', value.slice(0, 2))
  }

  const handleOnStreetChange = (event) => {
    const { value } = event.target
    formik.setFieldValue('street', value.slice(0, 200))
  }

  const handleOnComplementChange = (event) => {
    const { value } = event.target
    formik.setFieldValue('complement', value.slice(0, 50))
  }

  const handleOnDistrictChange = (event) => {
    const { value } = event.target
    formik.setFieldValue('district', value.slice(0, 100))
  }

  const handleOnCityChange = (event) => {
    const { value } = event.target
    formik.setFieldValue('city', value.slice(0, 200))
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <Typography className={classes.topTitle}>
              Pottencial Garantia Recursal
            </Typography>

            <Typography component="h1" className={classes.mainTitle}>
              Estamos quase lá!
            </Typography>

            <Typography component="span" className={classes.instructionText}>
              Preencha as informações solicitadas abaixo e clique
              <br />
              em <b>Próximo</b> para prosseguir.
            </Typography>

            <Hidden smUp>
              <Grid item sm={6} xs={12} className={classes.imageGrid}>
                <Box className={classes.image} />
              </Grid>
            </Hidden>

            <Typography component="h1" className={classes.title}>
              Dados do Reclamante:
            </Typography>

            <FormControl className={classes.formControl}>
              <CPFOrCNPJInput
                id="document"
                color="secondary"
                label="CPF ou CNPJ*"
                title="CPF ou CNPJ"
                fullWidth
                disabled={complainantDataIsLoading}
                {...formik.getFieldProps('document')}
                error={!!documentError || !!formik.errors.document}
                helperText={
                  documentError ||
                  (formik.touched.document && formik.errors.document)
                }
                onChange={handleOnChangeDocument}
              />
              <Loading isLoading={complainantDataIsLoading} isInput />
            </FormControl>
          </Grid>

          <Hidden xsDown>
            <Grid item sm={6} xs={12} className={classes.imageGrid}>
              <span className={classes.image} />
            </Grid>
          </Hidden>
        </Grid>

        {complainantDataIsSuccess && (
          <Grid container spacing={3}>
            <Grid item md={8} xs={12}>
              <TextField
                id="name"
                color="secondary"
                label={(
                  <>
                    <span>Nome ou razão social*</span>
                    <Tooltip title={(
                      <Typography>
                        <Box display="inline" fontSize={12}>
                          <p>Nome social é a <strong>escolha de um nome diferente do registrado oficialmente</strong>, feita pela própria <br />
                            pessoa para refletir sua identidade de gênero. Caso possua um nome social, é necessário enviar um e-mail no <br />
                            momento da cotação para <strong>atendimento.corretora@pottencial.com.br</strong> solicitando a inclusão do nome social e <br />
                            aguardar a confirmação do nosso time de atendimento para realizar a emissão. Após a emissão da apólice, não será <br />
                            mais possível fazer essa inclusão.</p>
                        </Box>
                      </Typography>
                    )}
                    />
                  </>
                )}
                title="Nome ou razão social"
                fullWidth
                disabled
                value={formik.getFieldProps('name').value}
                className={classes.formControl}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <FormControl className={classes.formControl}>
                <CEPInput
                  id="zipCode"
                  color="secondary"
                  label="CEP*:"
                  title="CEP"
                  fullWidth
                  disabled={complainantAddressIsLoading}
                  {...formik.getFieldProps('zipCode')}
                  error={!!formik.errors.zipCode}
                  helperText={formik.errors.zipCode}
                  onChange={handleOnChangeZipCode}
                />
                <Loading
                  isLoading={complainantAddressIsLoading && !isAddressSuccess}
                  isInput
                />
              </FormControl>
            </Grid>

            {isAddressSuccess && (
              <>
                <Grid item md={6} xs={12}>
                  <TextField
                    id="street"
                    color="secondary"
                    label="Logradouro*"
                    title="Logradouro"
                    fullWidth
                    {...formik.getFieldProps('street')}
                    error={!!formik.errors.street}
                    helperText={formik.errors.street}
                    className={classes.formControl}
                    onChange={handleOnStreetChange}
                  />
                </Grid>

                <Grid item md={2} xs={12}>
                  <TextField
                    id="number"
                    color="secondary"
                    label="Número*"
                    title="Número"
                    fullWidth
                    {...formik.getFieldProps('number')}
                    error={hasNumberError || !!formik.errors.number}
                    helperText={
                      (hasNumberError && 'Digite apenas números.') ||
                      formik.errors.number
                    }
                    className={classes.formControl}
                    onChange={handleOnNumberChange}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <TextField
                    id="complement"
                    color="secondary"
                    label="Complemento"
                    title="Complemento"
                    fullWidth
                    {...formik.getFieldProps('complement')}
                    error={!!formik.errors.complement}
                    helperText={formik.errors.complement}
                    className={classes.formControl}
                    onChange={handleOnComplementChange}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    id="district"
                    color="secondary"
                    label="Bairro*"
                    title="Bairro"
                    fullWidth
                    {...formik.getFieldProps('district')}
                    error={!!formik.errors.district}
                    helperText={formik.errors.district}
                    className={classes.formControl}
                    onChange={handleOnDistrictChange}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <TextField
                    id="city"
                    color="secondary"
                    label="Cidade*"
                    title="Cidade"
                    fullWidth
                    disabled={cityDisabled}
                    {...formik.getFieldProps('city')}
                    error={!!formik.errors.city}
                    helperText={formik.errors.city}
                    className={classes.formControl}
                    onChange={handleOnCityChange}
                  />
                </Grid>

                <Grid item md={2} xs={12}>
                  <TextField
                    id="state"
                    color="secondary"
                    label="Estado*"
                    title="Estado"
                    fullWidth
                    disabled={stateDisabled}
                    {...formik.getFieldProps('state')}
                    error={!!formik.errors.state}
                    helperText={formik.errors.state}
                    className={classes.formControl}
                    onChange={handleOnStateChange}
                  />
                </Grid>
              </>
            )}
          </Grid>
        )}

        <MessageHandler messages={saveErrors} type="error" />
      </Paper>

      <Grid
        pb={2}
        container
        justify="flex-end"
        spacing={2}
        className={classes.buttons}
      >
        <Grid item lg={3} sm={4} xs={12}>
          <Button
            color="primary"
            variant="outlined"
            title="Voltar"
            fullWidth
            onClick={handleBackButtonClick}
          >
            Voltar
          </Button>
        </Grid>

        <Grid item lg={3} sm={4} xs={12}>
          <Button
            color="primary"
            variant="contained"
            title="Próximo"
            fullWidth
            onClick={() => formik.submitForm()}
            disabled={!complainantDataIsSuccess || !formik.isValid}
          >
            Próximo
          </Button>
        </Grid>
      </Grid>

      <Loading isLoading={isLoading} />

      <Loading
        isLoading={processIsLoading}
        message="Aguarde alguns instantes enquanto verificamos os dados informados."
      />

      <PictureModal
        topTitle="POTTENCIAL GARANTIA RECURSAL"
        title="Seu crédito foi aprovado!"
        opened={openModalSuccess}
        handleClose={handleCoseModal}
        image={modalImageSuccess}
        mainButtonTitle="Cadastre-se"
        secondButtonTitle="Login"
        handleMainButtonClick={handleRedirectSimulator}
        handleSecondButtonClick={() => userManager.signinRedirect()}
      >
        Cadastre-se ou faça login para prosseguir com a emissão da sua apólice!
      </PictureModal>
    </>
  )
}

RegisterAddress.propTypes = {
  complainantDataIsLoading: PropTypes.bool,
  complainantData: PropTypes.object,
  complainantName: PropTypes.string,
  complainantDataIsSuccess: PropTypes.bool,
  complainantDataErrors: PropTypes.array,
  getComplainant: PropTypes.func.isRequired,
  getComplainantAddress: PropTypes.func.isRequired,
  resetComplainant: PropTypes.func.isRequired,
  complainantAddressIsLoading: PropTypes.bool,
  complainantAddressIsSuccess: PropTypes.bool,
  complainantAddressErrors: PropTypes.array,
  complainantAddressData: PropTypes.object,
  updateComplainantAddress: PropTypes.func.isRequired,
  defendantDocument: PropTypes.string,
  isLoading: PropTypes.bool,
  processIsLoading: PropTypes.bool,
  processNumber: PropTypes.string,
  saveErrors: PropTypes.array,
  approved: PropTypes.bool.isRequired,
  ignoreApproval: PropTypes.func.isRequired,
}

RegisterAddress.defaultProps = {
  complainantDataIsLoading: false,
  complainantData: {},
  complainantName: undefined,
  complainantDataIsSuccess: false,
  complainantDataErrors: [],
  complainantAddressIsLoading: false,
  complainantAddressIsSuccess: false,
  complainantAddressErrors: [],
  complainantAddressData: {},
  defendantDocument: undefined,
  isLoading: false,
  processIsLoading: false,
  processNumber: null,
  saveErrors: [],
}

export default RegisterAddress

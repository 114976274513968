import { put, takeLatest } from 'redux-saga/effects'

import { API } from 'services/api'
import { PeopleTypes, PeopleActions } from 'store/ducks/people'
import { setComplainant } from 'helpers/localStorage'
import { GET } from 'utils/constants/verbs'
import { GET_PEOPLE } from 'utils/constants/endpoints'

export function* watchSagas() {
  yield takeLatest(PeopleTypes.GET_DEFENDANT, getDefendant)
  yield takeLatest(PeopleTypes.GET_COMPLAINANT, getComplainant)
}

export function* getDefendant({ payload }) {
  try {
    const { data } = yield API({
      method: GET,
      url: GET_PEOPLE(payload),
    })

    const dataSuccess = {
      result: data,
      success: true,
      errors: [],
    }

    yield put(PeopleActions.getDefendantSuccess(dataSuccess))
  } catch (error) {
    yield put(PeopleActions.getDefendantFailure(error.response.data))
  }
}

export function* getComplainant({ payload }) {
  try {
    const { data } = yield API({
      method: GET,
      url: GET_PEOPLE(payload),
    })

    const dataSuccess = {
      result: data,
      success: true,
      errors: [],
    }

    setComplainant({ id: data.id, complainantDocument: data.document })
    yield put(PeopleActions.getComplainantSuccess(dataSuccess))
  } catch (error) {
    yield put(PeopleActions.getComplainantFailure(error.response.data))
  }
}

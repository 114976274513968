import { put, takeLatest } from 'redux-saga/effects'

import { API } from 'services/api'
import { ProcessTypes, ProcessActions } from 'store/ducks/process'
import { GET, POST, PUT } from 'utils/constants/verbs'
import { setProcess, setProposalData, setPeople } from 'helpers/localStorage'
import {
  SEARCH_PROCESS,
  SEARCH_BROKER,
  VERIFY_BROKER_APPOINTMENT,
  SAVE_PROCESS,
  GET_QUOTE,
  UPDATE_QUOTE,
} from 'utils/constants/endpoints'

export function* watchSagas() {
  yield takeLatest(ProcessTypes.SEARCH_PROCESS, searchProcess)
  yield takeLatest(ProcessTypes.SEARCH_BROKER, searchBroker)
  yield takeLatest(
    ProcessTypes.VERIFY_BROKER_APPOINTMENT,
    verifyBrokerAppointment
  )
  yield takeLatest(ProcessTypes.SAVE_PROCESS, saveProcess)
  yield takeLatest(ProcessTypes.GET_QUOTE, getQuote)
  yield takeLatest(ProcessTypes.UPDATE_QUOTE, updateQuote)
}

export function* searchProcess({ payload }) {
  try {
    const { data } = yield API({
      method: GET,
      url: SEARCH_PROCESS(payload),
    })

    setProcess(payload)
    yield put(ProcessActions.searchProcessSuccess(data))
  } catch (error) {
    yield put(ProcessActions.searchProcessFailure(error.response.data))
  }
}

export function* searchBroker({ payload }) {
  try {
    const { data } = yield API({
      method: POST,
      url: SEARCH_BROKER,
      data: payload,
    })

    const dataSuccess = {
      result: data,
      success: true,
      errors: [],
    }

    yield put(ProcessActions.searchBrokerSuccess(dataSuccess))
  } catch (error) {
    yield put(ProcessActions.searchBrokerFailure(error.response.data))
  }
}

export function* verifyBrokerAppointment({ payload }) {
  try {
    const { data } = yield API({
      method: GET,
      url: VERIFY_BROKER_APPOINTMENT(payload),
    })

    const dataSuccess = {
      result: data,
      success: true,
      errors: [],
    }

    yield put(ProcessActions.verifyBrokerAppointmentSuccess(dataSuccess))
  } catch (error) {
    yield put(
      ProcessActions.verifyBrokerAppointmentFailure(error.response.data)
    )
  }
}

export function* getQuote({ payload }) {
  try {
    const { data } = yield API({
      method: GET,
      url: GET_QUOTE(payload),
    })

    const dataSuccess = {
      result: data,
      success: true,
      errors: [],
    }

    yield put(ProcessActions.getQuoteSuccess(dataSuccess))
  } catch (error) {
    yield put(ProcessActions.getQuoteFailure(error.response.data))
  }
}

export function* updateQuote({ payload }) {
  try {
    const { data } = yield API({
      method: PUT,
      url: UPDATE_QUOTE(payload.quoteId),
      data: payload,
    })

    const dataSuccess = {
      result: data,
      success: true,
      errors: [],
    }

    setProposalData({
      status: 'quote',
      quoteId: data.id,
      number: payload.processNumber,
    })
    yield put(ProcessActions.updateQuoteSuccess(dataSuccess))
  } catch (error) {
    yield put(ProcessActions.updateQuoteFailure(error.response.data))
  }
}

export function* saveProcess({ payload }) {
  try {
    const { data } = yield API({
      method: POST,
      url: SAVE_PROCESS,
      data: payload,
    })

    const dataSuccess = {
      result: {
        id: data.quoteId,
        denied: data.status === 'DENIED' || data.status === 'UNAUTHORIZED',
        status: data.status,
      },
      success: true,
      errors: [],
    }

    setProposalData({
      status: 'quote',
      quoteId: data.quoteId,
      number: payload.properties.NUMERO_PROCESSO,
    })
    setPeople({
      defendantDocument: payload.properties.CNPJ_LICITANTE,
      complainantDocument: payload.properties.CNPJ_SEGURADO,
    })
    localStorage.removeItem('policy')
    yield put(ProcessActions.saveProcessSuccess(dataSuccess))
  } catch (error) {
    yield put(ProcessActions.saveProcessFailure(error.response.data))
  }
}

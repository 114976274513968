import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'

import { ReactComponent as IconError } from 'assets/svg/icon-error.svg'
import { ReactComponent as IconSuccess } from 'assets/svg/icon-success.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '12px 16px',
    width: '100%',
    borderRadius: '0.5rem',
    background: 'var(--red-rbg)',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  containerSuccess: {
    background: 'var(--green-rbg)',
  },
  message: {
    wordBreak: 'break-word',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
}))

const MessageHandler = ({ messages, message, type }) => {
  const classes = useStyles()

  if (messages.length || !!message) {
    return (
      <>
        <Box className={type === 'success' ? [classes.container, classes.containerSuccess] : classes.container}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs="auto">
              {type === 'success' ? <IconSuccess /> : <IconError />}
            </Grid>

            <Grid item xs>
              {messages.length ? (
                messages.map((m, index) => {
                  return (
                    <Typography variant="body2" className={classes.message} key={index}>
                      {m.message || m.Message}
                    </Typography>
                  )
                })
              ) : (
                <Typography variant="body2">
                  {message}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </>
    )
  }

  return null
}

MessageHandler.propTypes = {
  messages: PropTypes.array,
  message: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error']),
}

MessageHandler.defaultProps = {
  messages: [],
  message: '',
  type: 'error',
}

export default MessageHandler

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, makeStyles } from '@material-ui/core'

import { history } from 'store'
import urls from 'utils/constants/urls'
import { ProcessActions } from 'store/ducks/process'
import { ResourceActions } from 'store/ducks/resource'
import { Layout, Loading } from 'components/core'
import FormSimulate from 'components/presentation/Process/SimulateQuote/FormSimulate'
import ValueQuote from 'components/presentation/Process/SimulateQuote/ValueQuote'
import { getProposalData } from 'helpers/localStorage'

const ValidationFeedback = React.lazy(() =>
  import('../Register/ValidationFeedback')
)

const useStyles = makeStyles((theme) => ({
  root: {
    width: 920,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

const SimulateQuote = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { isSuccess: resourceIsSuccess, data: resourceData } = useSelector(
    (state) => state.resource
  )

  const { isSuccess: validityIsSuccess, data: validityData } = useSelector(
    (state) => state.resource.validity
  )

  const {
    validation: { denied },
    quote: {
      isLoading: quoteIsLoading,
      isSuccess: quoteSuccess,
      data: quoteData,
    },
  } = useSelector((state) => state.process)
  const {
    isLoading: updateLoading,
    isSuccess: updateSuccess,
    errors: updateErrors,
  } = useSelector((state) => state.process.update)

  const { quoteId } = getProposalData()

  const [hasDisable, setHasDisable] = useState(null)

  const handleRedirectProposal = () => {
    history.push(urls.ROUTES.proposal.path)
  }

  useEffect(() => {
    !resourceIsSuccess && dispatch(ResourceActions.getResource())
  }, [dispatch, resourceIsSuccess])

  useEffect(() => {
    !validityIsSuccess && dispatch(ResourceActions.getValidityOptions())
  }, [dispatch, validityIsSuccess])

  useEffect(() => {
    !quoteSuccess && dispatch(ProcessActions.getQuote(quoteId))
  }, [dispatch, quoteSuccess, quoteId])

  useEffect(() => {
    updateSuccess && dispatch(ProcessActions.getQuote(quoteId))
  }, [dispatch, updateSuccess, quoteId])

  const updateQuote = (values) => {
    const payload = {
      quoteId,
      resourceType: values.quote.resourceType,
      duration: parseInt(values.quote.duration),
      contractValue: parseFloat(values.quote.contractValue),
      processNumber: quoteData.processNumber,
    }
    dispatch(ProcessActions.updateQuote(payload))
  }

  return (
    <Layout>
      <Box className={classes.root}>
        {denied ? (
          <React.Suspense fallback={<Loading isLoading={updateLoading} />}>
            <ValidationFeedback />
          </React.Suspense>
        ) : (
          <Grid container direction="column">
            <Grid item xs={12}>
              <ValueQuote quoteData={quoteData} />
            </Grid>

            <Grid item xs={12}>
              <FormSimulate
                resourceData={resourceData}
                validityData={validityData}
                quoteData={quoteData}
                quoteIsLoading={quoteIsLoading}
                quoteSuccess={quoteSuccess}
                updateQuote={updateQuote}
                updateLoading={updateLoading}
                updateErrors={updateErrors}
                hasDisable={(disableCalc) => setHasDisable(disableCalc)}
                handleRedirectProposal={handleRedirectProposal}
                hasDisableButton={hasDisable}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Layout>
  )
}

export default SimulateQuote

import { createActions, createReducer } from 'reduxsauce'
import { getUserId } from 'helpers/getUser'

export const { Types: AuthTypes, Creators: AuthActions } = createActions({
  signup: ['payload'],
  signupSuccess: ['response', 'email'],
  signupFailure: ['errors'],
})

export const INITIAL_STATE = {
  isLoading: false,
  isSuccess: false,
  data: [],
  errors: [],
  user: {
    id: getUserId(),
    email: '',
    alreadyRegistered: false,
  },
}

const signup = () => ({
  ...INITIAL_STATE,
  isLoading: true,
})

const signupSuccess = (state, { response, email }) => ({
  ...state,
  isLoading: false,
  isSuccess: response.success,
  errors: [],
  user: {
    email,
  },
})

const signupFailure = (state, { errors }) => ({
  ...state,
  isLoading: false,
  isSuccess: false,
  errors,
})

export default createReducer(INITIAL_STATE, {
  [AuthTypes.SIGNUP]: signup,
  [AuthTypes.SIGNUP_SUCCESS]: signupSuccess,
  [AuthTypes.SIGNUP_FAILURE]: signupFailure,
})

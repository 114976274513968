import React from 'react'
import PropTypes from 'prop-types'

import {
  Bar,
  Bars,
  Step,
  Wrapper,
} from './styles'

const Stepper = ({ stepNumber }) => {

  return (
    <>
      <Bars>
        <Bar />
        <Bar />
        <Bar />
      </Bars>
      <Wrapper>
        <Step stepNumber={1} isCompleted={stepNumber > 1} isActive={stepNumber === 1}>Dados Iniciais</Step>
        <Step stepNumber={2} isCompleted={stepNumber > 2} isActive={stepNumber === 2}>Reclamante</Step>
        <Step stepNumber={3} isCompleted={stepNumber > 3} isActive={stepNumber === 3}>Cotação</Step>
        <Step stepNumber={4} isCompleted={stepNumber > 4} isActive={stepNumber === 4}>Proposta e Apólice</Step>
      </Wrapper>
    </>
  )
}

Stepper.propTypes = {
  stepNumber: PropTypes.number,
}

Stepper.defaultProps = {
  stepNumber: 1,
}

export default Stepper

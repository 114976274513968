import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from 'routes'
import ReactGA from 'react-ga'
import { history } from 'store'
import { ThemeProvider } from '@material-ui/core'

import { createTheme } from 'theme'
import { AuthProvider } from 'utils/auth/authProvider'
import GlobalStyle from 'styles/globalStyle'

const App = () => {
  const theme = createTheme()

  useEffect(() => {
    if (!window.ENV.GA_CONFIG) return

    ReactGA.initialize(window.ENV.GA_CONFIG, {
      gaOptions: {
        cookieDomain: 'auto',
        allowLinker: true
      }
    })
    ReactGA.plugin.require('linker')
    ReactGA.plugin.execute('linker', 'autoLink', [window.ENV.GA_AUTOLINK_DOMAIN])
    ReactGA.pageview(window.location.pathname + window.location.search)

    history.listen((location) => {
      ReactGA.pageview(location.pathname + location.search)
    })
  }, [])

  return (
    <AuthProvider>
      <Router>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Routes />
        </ThemeProvider>
      </Router>
    </AuthProvider>
  )
}

export default App

import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'

import icoCancel from 'assets/svg/icon-cancel.svg'

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 800,
    width: 800,
    borderRadius: 14,
    backgroundColor: '#f7f7f7',
  },
  dialogContainer: {
    padding: 0,
    '& .MuiDialogContent-root': {
      paddingTop: 0,
    },
  },
  buttons: {
    marginTop: 50,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  topTitle: {
    fontSize: 14,
    fontWeight: 'normal',
    color: theme.palette.primary.main,
    textTransform: 'upperCase',
  },
  title: {
    marginTop: theme.spacing(2),
    fontSize: 21,
    fontWeight: 'bold',
    color: '#000000',
  },
  body: {
    paddingTop: 55,
  },
  padding: {
    padding: 50,
  },
  boxGrid: {
    backgroundPosition: 'right',
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
    marginTop: -20,
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  close: {
    height: '25px',
    cursor: 'pointer',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  backButton: {
    width: '160px',
  },
}))

const PictureModal = ({
  topTitle,
  title,
  children,
  handleClose,
  opened,
  image,
  mainButtonTitle,
  secondButtonTitle,
  handleMainButtonClick,
  handleSecondButtonClick,
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={opened}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.dialog }}
      fullWidth
    >
      <DialogContent className={classes.dialogContainer}>
        <Box onClick={handleClose}>
          <img className={classes.close} src={icoCancel} alt="" />
        </Box>
        <Grid container className={classes.root}>
          <Grid item sm={8} className={classes.padding}>
            <Typography component="h4" className={classes.topTitle}>{topTitle}</Typography>
            <Typography variant="h6" className={classes.title}>{title}</Typography>
            <Grid item className={classes.body}>
              {children}
            </Grid>
            <Grid container justify="flex-end" spacing={2} className={classes.buttons}>
              {secondButtonTitle && (
                <Grid item>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleSecondButtonClick}
                    className={classes.backButton}
                    title="Voltar"
                  >
                    {secondButtonTitle}
                  </Button>
                </Grid>
              )}
              {mainButtonTitle && (
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleMainButtonClick}
                    className={classes.backButton}
                    title="Voltar"
                  >
                    {mainButtonTitle}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            className={classes.boxGrid}
            sm={4}
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

PictureModal.propTypes = {
  topTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  handleClose: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  image: PropTypes.any.isRequired,
  mainButtonTitle: PropTypes.string,
  secondButtonTitle: PropTypes.string,
  handleMainButtonClick: PropTypes.func,
  handleSecondButtonClick: PropTypes.func,
}

PictureModal.defaultProps = {
  mainButtonTitle: undefined,
  secondButtonTitle: undefined,
  handleMainButtonClick: () => { },
  handleSecondButtonClick: () => { },
}

export default PictureModal

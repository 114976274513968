import { createActions, createReducer } from 'reduxsauce'

export const { Types: AddressTypes, Creators: AddressActions } = createActions({
  getDefendantAddress: ['payload'],
  getDefendantAddressSuccess: ['response'],
  getDefendantAddressFailure: ['errors'],

  getComplainantAddress: ['payload'],
  getComplainantAddressSuccess: ['response'],
  getComplainantAddressFailure: ['errors'],

  updateDefendantAddress: ['payload', 'document'],
  updateDefendantAddressSuccess: ['response'],
  updateDefendantAddressFailure: ['errors'],

  updateComplainantAddress: ['payload', 'document'],
  updateComplainantAddressSuccess: ['response'],
  updateComplainantAddressFailure: ['errors'],

  resetAddress: [],
})

export const INITIAL_STATE = {
  defendant: {
    isLoading: false,
    isSuccess: false,
    data: {},
    errors: [],
    update: {
      isSuccess: false,
    },
  },
  complainant: {
    isLoading: false,
    isSuccess: false,
    data: {},
    errors: [],
    update: {
      isSuccess: false,
    },
  },
}

const getDefendantAddress = (state) => ({
  ...state,
  defendant: {
    isLoading: true,
    update: {
      isSuccess: false,
    },
  },
})

const getDefendantAddressSuccess = (state, { response }) => ({
  ...state,
  defendant: {
    isLoading: false,
    isSuccess: response.success,
    data: response.result,
    update: {
      isSuccess: false,
    },
  },
})

const getDefendantAddressFailure = (state, { errors }) => ({
  ...state,
  defendant: {
    isSuccess: false,
    errors,
    update: {
      isSuccess: false,
    },
  },
})

const getComplainantAddress = (state) => ({
  ...state,
  complainant: {
    isLoading: true,
  },
})

const getComplainantAddressSuccess = (state, { response }) => ({
  ...state,
  complainant: {
    isLoading: false,
    isSuccess: response.success,
    data: response.result,
  },
})

const getComplainantAddressFailure = (state, { errors }) => ({
  ...state,
  complainant: {
    isSuccess: false,
    errors,
  },
})

const updateDefendantAddress = (state) => ({
  ...state,
  defendant: {
    ...state.defendant,
    isLoading: true,
  },
})

const updateDefendantAddressSuccess = (state, { response }) => ({
  ...state,
  defendant: {
    ...state.defendant,
    isLoading: false,
    update: {
      isSuccess: response.success,
    },
  },
})

const updateDefendantAddressFailure = (state, { errors }) => ({
  ...state,
  defendant: {
    ...state.defendant,
    isLoading: false,
    errors,
  },
})

const updateComplainantAddress = (state) => ({
  ...state,
  complainant: {
    ...state.complainant,
    isLoading: true,
  },
})

const updateComplainantAddressSuccess = (state, { response }) => ({
  ...state,
  complainant: {
    ...state.complainant,
    isLoading: false,
    update: {
      isSuccess: response.success,
    },
  },
})

const updateComplainantAddressFailure = (state, { errors }) => ({
  ...state,
  complainant: {
    ...state.complainant,
    isLoading: false,
    errors,
  },
})

const resetAddress = () => ({
  ...INITIAL_STATE,
})

export default createReducer(INITIAL_STATE, {
  [AddressTypes.GET_DEFENDANT_ADDRESS]: getDefendantAddress,
  [AddressTypes.GET_DEFENDANT_ADDRESS_SUCCESS]: getDefendantAddressSuccess,
  [AddressTypes.GET_DEFENDANT_ADDRESS_FAILURE]: getDefendantAddressFailure,

  [AddressTypes.GET_COMPLAINANT_ADDRESS]: getComplainantAddress,
  [AddressTypes.GET_COMPLAINANT_ADDRESS_SUCCESS]: getComplainantAddressSuccess,
  [AddressTypes.GET_COMPLAINANT_ADDRESS_FAILURE]: getComplainantAddressFailure,

  [AddressTypes.UPDATE_DEFENDANT_ADDRESS]: updateDefendantAddress,
  [AddressTypes.UPDATE_DEFENDANT_ADDRESS_SUCCESS]: updateDefendantAddressSuccess,
  [AddressTypes.UPDATE_DEFENDANT_ADDRESS_FAILURE]: updateDefendantAddressFailure,

  [AddressTypes.UPDATE_COMPLAINANT_ADDRESS]: updateComplainantAddress,
  [AddressTypes.UPDATE_COMPLAINANT_ADDRESS_SUCCESS]: updateComplainantAddressSuccess,
  [AddressTypes.UPDATE_COMPLAINANT_ADDRESS_FAILURE]: updateComplainantAddressFailure,

  [AddressTypes.RESET_ADDRESS]: resetAddress,
})

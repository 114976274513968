import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Grid,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core'

import urls from 'utils/constants/urls'
import userManager from 'utils/auth/userManager'

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginBottom: theme.spacing(4),
  },
  backButtonGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  backButton: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    fontSize: '0.875rem',
    '&:hover': {
      textDecoration: 'none',
    }
  },
}))

const SignupFeedback = ({ email }) => {
  const classes = useStyles()

  return (
    <>
      <Box mb={6} mt={8} align="center">
        <Typography variant="h5">Cadastro realizado!</Typography>
      </Box>

      <Box mb={4} align="center">
        <Typography variant="body2" align="center">
          Um e-mail contendo informações para cadastrar uma nova senha foi enviado para{' '}
          <b>{email}</b>
          .
        </Typography>
      </Box>

      <Box mb={8} align="center">
        <Typography variant="body2" align="center">
          Quando tudo estiver pronto, faça login utilizando este e-mail e sua nova senha.
        </Typography>
      </Box>

      <Grid
        container
        spacing={3}
        className={classes.buttons}
      >
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            title="Login"
            fullWidth
            onClick={() => userManager.signinRedirect()}
          >
            Login
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.backButtonGrid}>
          <Link
            component="button"
            className={classes.backButton}
            type="button"
            onClick={() => { window.location.replace(urls.ROUTES.app.path) }}
          >
            VOLTAR
          </Link>
        </Grid>
      </Grid>
    </>
  )
}

SignupFeedback.propTypes = {
  email: PropTypes.string.isRequired,
}

export default SignupFeedback

import { put, takeLatest } from 'redux-saga/effects'

import { API } from 'services/api'
import { ResourceTypes, ResourceActions } from 'store/ducks/resource'
import { GET } from 'utils/constants/verbs'
import {
  GET_RESOURCE_TYPES,
  GET_VALIDITY_OPTIONS,
} from 'utils/constants/endpoints'

export function* watchSagas() {
  yield takeLatest(ResourceTypes.GET_RESOURCE, getResource)
  yield takeLatest(ResourceTypes.GET_VALIDITY_OPTIONS, getValidityOptions)
}

export function* getResource() {
  try {
    const { data } = yield API({
      method: GET,
      url: GET_RESOURCE_TYPES,
    })

    const dataSuccess = {
      result: data,
      success: true,
      errors: [],
    }

    yield put(ResourceActions.getResourceSuccess(dataSuccess))
  } catch (error) {
    yield put(ResourceActions.getResourceFailure(error.response?.data))
  }
}

export function* getValidityOptions() {
  try {
    const { data } = yield API({
      method: GET,
      url: GET_VALIDITY_OPTIONS,
    })

    const dataSuccess = {
      result: data,
      success: true,
      errors: [],
    }

    yield put(ResourceActions.getValidityOptionsSuccess(dataSuccess))
  } catch (error) {
    yield put(ResourceActions.getValidityOptionsFailure(error.response.data))
  }
}

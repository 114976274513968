const base = window.ENV.API_URL
const service = 'api'
const garantiaProductKey = 'garantiaRecursal'

export const BASE_URL = `${base}/${service}/`

export const SIGNUP = 'users'

export const SEARCH_PROCESS = (processNumber) =>
  `judicial-process/${processNumber}`

export const SEARCH_BROKER = 'brokers/search'

export const VERIFY_BROKER_APPOINTMENT = (policyHolderDocument) =>
  `brokers/${garantiaProductKey}/verify-appointment/${policyHolderDocument}`

export const SAVE_PROCESS = 'quotes'

export const GET_QUOTE = (quoteId) => `${garantiaProductKey}/quotes/${quoteId}`

export const UPDATE_QUOTE = (quoteId) =>
  `${garantiaProductKey}/quotes/${quoteId}`

export const GET_PEOPLE = (document) => `persons/${document}`

export const GET_ADDRESS = (zipCode) => `addresses/${zipCode}`

export const GET_RESOURCE_TYPES = '/resources/search-types/1'

export const GET_VALIDITY_OPTIONS = 'resources/list-period-types'

export const UPDATE_ADDRESS = (document) => `persons/${document}/address`

export const CREATE_PROPOSAL = 'proposals'

export const GET_PROPOSAL = (proposalId) =>
  `${garantiaProductKey}/proposals/${proposalId}`

export const ACCEPT_PROPOSAL = (proposalId) =>
  `${garantiaProductKey}/proposals/${proposalId}/accept`

export const CREATE_POLICY = 'policies'

export const GET_POLICY = (policyId) =>
  `${garantiaProductKey}/policies/${policyId}`

export const GET_MINUTA_DOCUMENT = (quoteId) =>
  `${garantiaProductKey}/quotes/${quoteId}/draft`

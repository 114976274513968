import React from 'react'
import { Box } from '@material-ui/core'

import Layout from 'components/core/Layout'
import ErrorFeedback from 'components/presentation/ErrorFeedback'

const ErrorFeedbackContainer = () => {
  return (
    <Layout>
      <Box>
        <ErrorFeedback />
      </Box>
    </Layout>
  )
}

export default ErrorFeedbackContainer

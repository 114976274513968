import { makeUserManager } from 'react-oidc'
import { WebStorageStateStore } from 'oidc-client'

import { history } from 'store'
import urls from 'utils/constants/urls'

const userManagerConfig = {
  client_id: window.ENV.CLIENT_ID,
  redirect_uri: `${window.ENV.BASE_URl}/${window.ENV.REDIRECT_URI}`,
  response_type: 'token id_token',
  scope: 'openid profile pottencial-garantiarecursal-bff pottencial-garantia-bidperformance-bff',
  authority: window.ENV.AUTHORITY,
  silent_redirect_uri: `${window.ENV.BASE_URl}/${window.ENV.SILENT_RENEW_REDIRECT_URI}`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true,
  post_logout_redirect_uri: `${window.ENV.BASE_URl}/${window.ENV.POST_LOGOUT_REDIRECT_URI}`,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
}

const userManager = makeUserManager(userManagerConfig)

userManager.events.addAccessTokenExpiring(() => {
  userManager.signinSilent()
})

userManager.events.addAccessTokenExpired(() => {
  localStorage.clear()
  history.push(urls.ROUTES.app.path)
})

export default userManager

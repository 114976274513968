import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'

import useUtils from 'hooks/useUtils'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: 10,
    padding: 24,
  },
  topTitle: {
    fontSize: 12,
    fontFamily: 'var(--font-poppins)',
    fontWeight: '600',
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.main,
    textTransform: 'upperCase',
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'var(--font-poppins)',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  titleLine: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    width: 30,
    height: 3,
    background: theme.palette.primary.main,
  },
  valuesPaper: {
    width: '100%',
    padding: 20,
    marginBottom: theme.spacing(2),
  },
  policyHolderGrid: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      borderRight: '1px solid #D6D6D6',
    },
  },
  brokerGrid: {
    textAlign: 'center',
  },
}))

const ValueQuote = ({ quoteData }) => {
  const classes = useStyles()
  const { formatCNPJ } = useUtils()

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography className={classes.topTitle}>
              Pottencial Garantia Recursal
            </Typography>

            <Typography component="h1" className={classes.title}>
              Resumo dos dados iniciais:
            </Typography>

            <span className={classes.titleLine} />
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={0} className={classes.valuesPaper}>
              <Grid container direction="row" alignItems="center" justify="center" spacing={3}>
                <Grid item sm={6} xs={12} className={classes.policyHolderGrid}>
                  <Grid container direction="column" alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" component="span">
                        CNPJ da Corretora:
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="body2" component="span">
                        {formatCNPJ(quoteData?.brokerDocumentNumber)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sm={6} xs={12} className={classes.brokerGrid}>
                  <Grid container direction="column" alignItems="center">
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" component="span">
                        Nome da Corretora:
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="body2" component="span" className={classes}>
                        {quoteData?.brokerName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item lg={6} xs={12}>
            <Box display="inline" mr={1}>
              <Typography variant="subtitle2" component="span">
                CNPJ do tomador:
              </Typography>
            </Box>
            <Hidden smUp><br /></Hidden>
            <Typography variant="body2" component="span">
              {formatCNPJ(quoteData?.policyHolderDocumentNumber)}
            </Typography>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Box display="inline" mr={1}>
              <Typography variant="subtitle2" component="span">
                Razão social do tomador:
              </Typography>
            </Box>
            <Hidden smUp><br /></Hidden>
            <Typography variant="body2" component="span">
              {quoteData?.policyholderName}
            </Typography>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Box display="inline" mr={1}>
              <Typography variant="subtitle2" component="span">
                CPF ou CNPJ do reclamante:
              </Typography>
            </Box>
            <Hidden smUp><br /></Hidden>
            <Typography variant="body2" component="span">
              {formatCNPJ(quoteData?.insuredDocumentNumber)}
            </Typography>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Box display="inline" mr={1}>
              <Typography variant="subtitle2" component="span">
                Nome ou Razão social do reclamante:
              </Typography>
            </Box>
            <Hidden smUp><br /></Hidden>
            <Typography variant="body2" component="span">
              {quoteData?.insuredName}
            </Typography>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Box display="inline" mr={1}>
              <Typography variant="subtitle2" component="span">
                Vara e comarca do processo:
              </Typography>
            </Box>
            <Hidden smUp><br /></Hidden>
            <Typography variant="body2" component="span">
              {quoteData?.comarcaNumber}
            </Typography>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Box display="inline" mr={1}>
              <Typography variant="subtitle2" component="span">
                Número do processo:
              </Typography>
            </Box>
            <Hidden smUp><br /></Hidden>
            <Typography variant="body2" component="span">
              {quoteData?.processNumber}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

ValueQuote.propTypes = {
  quoteData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]).isRequired,
}

ValueQuote.defaultProps = {
  quoteData: [],
}

export default ValueQuote

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Grid,
  makeStyles,
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'

import userManager from 'utils/auth/userManager'
import { getUserEmail } from 'helpers/getUser'
import { PictureModal } from 'components'
import modalImage from 'assets/images/image-home.jpg'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    alignItems: 'center',
  },
  rootWithoutHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    [theme.breakpoints.down('lg')]: {
      marginLeft: 'auto',
    },
  },
  helpGrid: {
    padding: '0 10px',
    margin: '0 10px',
    border: `solid ${theme.palette.primary.contrastText}`,
    borderWidth: '0 1px 0 1px',
    [theme.breakpoints.down('sm')]: {
      borderWidth: '0 1px 0 0',
    },
  },
  userInfoGrid: {
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  helpButton: {
    color: theme.palette.primary.contrastText,
    fontSize: '1.5rem',
    borderRadius: 50,
  },
  logoutButton: {
    color: theme.palette.primary.contrastText,
    fontSize: '1rem',
  },
  logoutButtonWithoutHeader: {
    fontSize: '1rem',
  },
  helpIconWithoutHeader: {
    color: theme.palette.primary.main,
  },
}))

const UserArea = ({ hideHeaderAndFooter }) => {
  const classes = useStyles()

  const [openModal, setOpenModal] = useState(false)

  const signout = () => {
    userManager.signoutRedirect()
  }

  return (
    <>
      <Box className={hideHeaderAndFooter ? classes.rootWithoutHeader : classes.root}>
        <Grid container alignItems="center">
          <Grid item md xs="auto" className={classes.container}>
            <Grid container alignItems="center" justify="flex-end">
              <Grid item xs md="auto" className={classes.userInfoGrid}>
                Olá, {getUserEmail()}
              </Grid>

              <Grid item className={classes.helpGrid}>
                <Button title="O que é o Seguro Garantia Recursal?" onClick={() => { setOpenModal(true) }} className={classes.helpButton}>
                  <HelpIcon fontSize="inherit" className={hideHeaderAndFooter ? classes.helpIconWithoutHeader : ''} />
                </Button>
              </Grid>

              <Grid item xs md="auto">
                <Button title="Sair" onClick={() => signout()} className={hideHeaderAndFooter ? classes.logoutButtonWithoutHeader : classes.logoutButton}>
                  Sair
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <PictureModal
        topTitle="POTTENCIAL GARANTIA RECURSAL"
        title="O que é o Seguro Garantia Recursal?"
        opened={openModal}
        handleClose={() => { setOpenModal(false) }}
        image={modalImage}
      >
        Esta é uma modalidade de seguro que pode ser utilizado nos processos que
        tramitam na Justiça do Trabalho, para fins de Depósitos Recursais, quando
        há interposição dos recursos ao invés de se realizar o pagamento em dinheiro.
      </PictureModal>
    </>
  )
}

UserArea.propTypes = {
  hideHeaderAndFooter: PropTypes.bool,
}

UserArea.defaultProps = {
  hideHeaderAndFooter: false,
}

export default UserArea

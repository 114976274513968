import React from 'react'

import { AuthConsumer } from 'utils/auth/authProvider'
import Loading from 'components/core/Loading'

const SigninCallback = () => (
  <AuthConsumer>
    {({ signinRedirectCallback }) => {
      signinRedirectCallback()
      return <Loading isLoading={true} />
    }}
  </AuthConsumer>
)

export default SigninCallback

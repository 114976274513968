import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import IntlCurrencyInput from 'react-intl-currency-input'

const Config = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        currency: 'BRL',
        style: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
}

const CurrencyInput = (props) => {
  const { value } = props

  return (
    <IntlCurrencyInput
      {...props}
      autoSelect
      currency="BRL"
      config={Config}
      value={value ? parseFloat(value) : 0}
      component={TextField}
    />
  )
}

CurrencyInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
}

CurrencyInput.defaultProps = {
  value: 0,
}

export default CurrencyInput

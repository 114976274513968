import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Box,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'

import { AuthActions } from 'store/ducks/auth'
import useUtils from 'hooks/useUtils'
import Layout from 'components/core/Layout'
import Logo from 'components/core/Logo'
import Auth from 'components/presentation/Auth'
import SignupFeedback from 'components/presentation/Auth/SignupFeedback'
import imageSidebar from 'assets/images/image-sidebar.jpg'
import ico from 'assets/svg/icon-pottencial.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  rootGrid: {
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      height: 'auto',
    },
  },
  bgContainer: {
    backgroundImage: `url(${imageSidebar})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '@media (max-width: 599px)': {
      backgroundPositionY: '-85px',
      backgroundRepeat: 'initial',
      height: '255px',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: theme.spacing(1),
    },
  },
  title: {
    '@media (max-width: 599px)': {
      fontSize: '1rem',
    },
  },
  formContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
    color: '#333333',
    '@media (max-width: 599px)': {
      paddingTop: '95px',
    },
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 160,
    height: 60,
    backgroundColor: 'var(--primary)',
    margin: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 390,
    width: '100%',
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(3),
    },
  },
}))

const AuthContainer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { getOnlyNumber } = useUtils()

  const [filteredErrors, setFilteredErrors] = useState([])
  const [alreadyRegistered, setAlreadyRegistered] = useState(false)

  const {
    isLoading,
    isSuccess,
    errors,
    user: { email },
  } = useSelector(({ auth }) => auth)

  useEffect(() => {
    let isBadGateway = false

    // eslint-disable-next-line array-callback-return
    errors.length && errors.map((error) => {
      if (error.Message === 'Bad Gateway') {
        isBadGateway = true
      }
    })

    isBadGateway
      ? setAlreadyRegistered(true)
      : setFilteredErrors(errors)
  }, [errors])

  const handleSignUp = (values) => {
    setAlreadyRegistered(false)
    setFilteredErrors([])

    const payload = {
      name: values.name,
      cpf: getOnlyNumber(values.document),
      email: values.email,
      returnUrl: window.ENV.RETURN_URL,
    }

    dispatch(AuthActions.signup(payload))
  }

  return (
    <Layout>
      <Box className={classes.root}>
        <Grid container className={classes.rootGrid}>
          <Grid item className={classes.bgContainer} sm={5}>
            <Box className={classes.logo}>
              <Logo hideHeaderAndFooter />
            </Box>
          </Grid>
          <Grid item sm={7}>
            <Box className={classes.formContainer}>
              <Box className={classes.titleContainer}>
                <img src={ico} alt="Pottencial" aria-label="Pottencial" />
                <Typography variant="h6" className={classes.title} color="primary">POTTENCIAL GARANTIA RECURSAL</Typography>
              </Box>

              <form noValidate autoComplete="off" className={classes.form}>
                {isSuccess && !alreadyRegistered ? (
                  <SignupFeedback email={email} />
                ) : (
                  <Auth
                    handleSignUp={handleSignUp}
                    isLoading={isLoading}
                    errorsSignup={filteredErrors}
                    alreadyRegistered={alreadyRegistered}
                  />
                )}
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  )
}

export default AuthContainer

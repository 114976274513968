import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { urls } from 'utils/constants'
import usePeople from './usePeople'
import useProcess from './useProcess'

export default () => {
  const { data: resourceData } = useSelector((store) => store.resource)
  const { processNumber, processData } = useProcess()
  const { complainantData } = usePeople()
  const history = useHistory()

  const getMapperObjectTemplateValue = () => ({
    '(GSI-ProcessoPadraoModalidade-GSI)': processNumber,
    '(GSI-NomeReclamente-GSI)': complainantData.name,
    '(GSI-CpfReclamente-GSI)': complainantData.document,
    '(GSI-TribunalPadraoModalidade-GSI) (GSI-VaraPadraoModalidade-GSI)':
      processData.process.comarca,
    '(GSI-PercentualAgravo-GSI)': 30,
  })

  const getRiskObjectText = () => {
    const processTypeDescription = processData.process.resourceType.description
    const objectTemplate = resourceData.find(
      (template) => template.description === processTypeDescription
    )?.objectTemplate

    if (!objectTemplate) history.push(urls.ROUTES.error.path)

    let riskObjectText = objectTemplate

    Object.entries(getMapperObjectTemplateValue()).forEach(([key, value]) => {
      riskObjectText = riskObjectText.replace(key, value)
    })

    return riskObjectText
  }

  return { resourceData, getRiskObjectText }
}

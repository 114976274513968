import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, makeStyles } from '@material-ui/core'

import { history } from 'store'
import { PolicyActions } from 'store/ducks/policy'
import urls from 'utils/constants/urls'
import { getPolicyData } from 'helpers/localStorage'
import Layout from 'components/core/Layout'
import PolicyFeedback from 'components/presentation/PolicyFeedback'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 750,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

const PolicyFeedbackContainer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { policyId, premium, policyNumber } = getPolicyData()

  const {
    isLoading,
    errors,
    duration,
    automaticRenewalDate,
  } = useSelector(({ policy }) => policy)

  useEffect(() => {
    !policyId
      ? history.push(urls.ROUTES.app.path)
      : dispatch(PolicyActions.getPolicy(policyId))
  }, [dispatch, policyId])

  useEffect(() => {
    dispatch(PolicyActions.resetErrors())
  }, [dispatch])

  return (
    <Layout>
      <Box className={classes.root}>
        <PolicyFeedback
          isLoading={isLoading}
          policyValue={premium}
          policyNumber={policyNumber}
          duration={duration}
          automaticRenewalDate={automaticRenewalDate}
          errors={errors}
        />
      </Box>
    </Layout>
  )
}

export default PolicyFeedbackContainer

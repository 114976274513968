import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Tooltip as TooltipMui, Fade } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const useStyles = makeStyles((theme) => ({
    tooltipIcon: {
        cursor: 'pointer',
        width: 22,
        position: 'absolute',
        top: '-0.25rem',
    },
    tooltip: {
        width: 256,
        backgroundColor: '#626262',
        fontSize: 12,
        fontFamily: 'Open Sans',
        padding: 14,
        fontWeight: 400,
        textAlign: 'center',
    },
    tooltipArrow: {
        color: '#626262',
    },
}))

export default function Tooltip({ title }) {
    const classes = useStyles()

    return (

        <TooltipMui
            title={title}
            placement="top"
            arrow
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 300 }}
            classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        >
            <HelpOutlineIcon
                aria-label="dica"
                className={classes.tooltipIcon}
            />
        </TooltipMui>

    )
}

Tooltip.propTypes = {
    title: PropTypes.string.isRequired,
}

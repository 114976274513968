import React from 'react'
import {
  Box,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core'

import urls from 'utils/constants/urls'

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: '0.625rem 0',
    display: 'flex',
    alignItems: 'flex-start',
    background: 'var(--primary)',
  },
  wrapper: {
    display: 'flex',
    margin: '0 auto',
    width: '100%',
    maxWidth: 1200,
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      padding: '0 1.875rem',
      width: '100%',
    },
  },
  text: {
    fontSize: 14,
    lineHeight: 1.5,
    color: 'var(--white)',
  },
  link: {
    textDecoration: 'underline',
    marginTop: -3.5,
    cursor: 'pointer',
    color: 'var(--white)',
    '&:hover': {
      color: 'var(--black)',
    },
  },
}))

const Footer = () => {
  const classes = useStyles()

  return (
    <Box component="footer" className={classes.footer}>
      <Box className={classes.wrapper}>
        <Typography component="p" className={classes.text}>
          Pottencial Seguradora S.A.
          - CNPJ: 11.699.534/0001-74
          - SUSEP: 03069
          - SAC: (31) 2121-7777 | 0800 606 7688
          - Ouvidoria: 0800 200 1080 -{' '}

          <Link
            component="span"
            className={classes.link}
            title="Ir para a página de política de privacidade"
            onClick={() => { window.open(urls.LINKS.privacyPolicy, '_blank') }}
          >
            Política de Privacidade
          </Link>
        </Typography>
      </Box>
    </Box>
  )
}

export default Footer

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Link,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'

import useYup from 'hooks/useYup'
import urls from 'utils/constants/urls'
import userManager from 'utils/auth/userManager'
import { CPFInput, MessageBox } from 'components'
import { Loading, MessageHandler } from 'components/core'

const useStyles = makeStyles((theme) => ({
  terms: {
    fontSize: '0.875rem',
    fontWight: 600,
    marginTop: 10,
  },
  link: {
    textDecoration: 'underline',
    marginTop: -3.5,
    cursor: 'pointer',
  },
  privacryPolicyGrid: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  backButtonGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  backButton: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    fontSize: '0.875rem',
    '&:hover': {
      textDecoration: 'none',
    }
  },
}))

const Auth = ({
  handleSignUp,
  isLoading,
  errorsSignup,
  alreadyRegistered,
}) => {
  const classes = useStyles()
  const { cpf } = useYup()

  const formik = useFormik({
    initialValues: {
      ...{
        document: '',
        name: '',
        email: ''
      }
    },
    validationSchema: Yup.object().shape({
      document: cpf.required('O CPF é necessário.'),
      name: Yup.string().required('O nome é necessário.')
        .min(6, 'O nome deve ter no mínimo 6 caracteres.')
        .max(120, 'O nome deve ter no máximo 120 caracteres.'),
      email: Yup.string().email('O e-mail não é válido.').required('O e-mail é necessário.')
    }),
    onSubmit: (formData) => {
      handleSignUp(formData)
    },
  })

  const [isPrivacyPolicyAccept, setIsPrivacyPolicyAccept] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    alreadyRegistered && setOpenModal((openModal) => !openModal)
  }, [alreadyRegistered])

  const handleChangePrivacyPolicyCheck = (event) => {
    setIsPrivacyPolicyAccept(event.target.checked)
    formik.validateForm()
  }

  const handleSubmitForm = () => {
    formik.submitForm()
  }

  return (
    <>
      <Box mb={2} mt={8} align="center">
        <Typography variant="h4">Seja bem-vindo(a)!</Typography>
      </Box>

      <Box mb={4} mt={1}>
        <Typography align="center">NOVO CADASTRO</Typography>
      </Box>

      <CPFInput
        id="document"
        color="secondary"
        label="CPF:"
        title="CPF"
        fullWidth
        {...formik.getFieldProps('document')}
        error={formik.touched.document && !!formik.errors.document}
        helperText={formik.touched.document && formik.errors.document}
      />

      <TextField
        id="name"
        color="secondary"
        label="Nome"
        title="Nome"
        fullWidth
        {...formik.getFieldProps('name')}
        error={formik.touched.name && !!formik.errors.name}
        helperText={formik.touched.name && formik.errors.name}
      />

      <TextField
        id="email"
        color="secondary"
        label="E-mail:"
        title="E-mail"
        fullWidth
        {...formik.getFieldProps('email')}
        error={formik.touched.email && !!formik.errors.email}
        helperText={formik.touched.email && formik.errors.email}
      />

      <Grid container className={classes.privacryPolicyGrid}>
        <Grid item xs="auto">
          <Checkbox
            id="privacyPolicyAccept"
            color="primary"
            checked={isPrivacyPolicyAccept}
            onChange={handleChangePrivacyPolicyCheck}
          />
        </Grid>

        <Grid item xs>
          <Typography className={classes.terms}>
            Concordo com a coleta de dados necessários para o cadastro, de
            acordo com a{' '}
            <Link
              component="span"
              className={classes.link}
              onClick={() => { window.open(urls.LINKS.privacyPolicy, '_blank') }}
            >
              Política de Privacidade da Pottencial
            </Link>
            .
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        className={classes.buttons}
      >
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="contained"
            title="Cadastrar"
            fullWidth
            disabled={!isPrivacyPolicyAccept || !formik.isValid}
            onClick={handleSubmitForm}
          >
            CADASTRAR
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.backButtonGrid}>
          <Link
            component="button"
            className={classes.backButton}
            type="button"
            onClick={() => { window.location.replace(urls.ROUTES.app.path) }}
          >
            VOLTAR
          </Link>
        </Grid>
      </Grid>

      <Loading isLoading={isLoading} />

      <MessageHandler messages={errorsSignup} type="error" />

      <MessageBox
        type="error"
        opened={openModal}
        handleClose={() => { setOpenModal(false) }}
        handlePrimary={() => userManager.signinRedirect()}
        labelPrimary="Login"
        title="Falha ao tentar cadastrar o usuário!"
        text="Verifique se o e-mail e o CPF estão corretos. Caso você seja cadastrado, faça seu login. Se o problema persistir, entre em contato com a gente por meio do chat do lado inferior direito da tela."
      />
    </>
  )
}

Auth.propTypes = {
  handleSignUp: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  errorsSignup: PropTypes.array,
  alreadyRegistered: PropTypes.bool,
}

Auth.defaultProps = {
  isLoading: false,
  errorsSignup: [],
  alreadyRegistered: false,
}

export default Auth

import { createActions, createReducer } from 'reduxsauce'

export const {
  Types: ProposalTypes,
  Creators: ProposalActions,
} = createActions({
  createProposal: ['payload'],
  createProposalSuccess: ['response'],
  createProposalFailure: ['errors'],

  getProposal: ['payload'],
  getProposalSuccess: ['response'],
  getProposalFailure: ['errors'],

  acceptProposal: ['payload'],
  acceptProposalSuccess: ['response'],
  acceptProposalFailure: ['errors'],

  resetErrors: [],
})

export const INITIAL_STATE = {
  isLoading: false,
  isSuccess: false,
  acceptIsLoading: false,
  acceptIsSuccess: false,
  proposal: {},
  errors: [],
}

const createProposal = (state) => ({
  ...state,
  isLoading: true,
})

const createProposalSuccess = (state) => ({
  ...state,
  isLoading: false,
  isSuccess: false,
})

const createProposalFailure = (state, { errors }) => ({
  ...state,
  isLoading: false,
  isSuccess: false,
  errors,
})

const getProposal = (state) => ({
  ...state,
  isLoading: true,
})

const getProposalSuccess = (state, { response }) => ({
  ...state,
  isLoading: false,
  isSuccess: true,
  proposal: response,
})

const getProposalFailure = (state, { errors }) => ({
  ...state,
  isLoading: false,
  isSuccess: false,
  errors,
})

const acceptProposal = (state) => ({
  ...state,
  acceptIsLoading: true,
})

const acceptProposalSuccess = (state, { response }) => ({
  ...state,
  acceptIsLoading: false,
  acceptIsSuccess: true,
})

const acceptProposalFailure = (state, { errors }) => ({
  ...state,
  acceptIsLoading: false,
  acceptIsSuccess: false,
  errors,
})

const resetErrors = (state) => ({
  ...state,
  errors: [],
})

export default createReducer(INITIAL_STATE, {
  [ProposalTypes.CREATE_PROPOSAL]: createProposal,
  [ProposalTypes.CREATE_PROPOSAL_SUCCESS]: createProposalSuccess,
  [ProposalTypes.CREATE_PROPOSAL_FAILURE]: createProposalFailure,

  [ProposalTypes.GET_PROPOSAL]: getProposal,
  [ProposalTypes.GET_PROPOSAL_SUCCESS]: getProposalSuccess,
  [ProposalTypes.GET_PROPOSAL_FAILURE]: getProposalFailure,

  [ProposalTypes.ACCEPT_PROPOSAL]: acceptProposal,
  [ProposalTypes.ACCEPT_PROPOSAL_SUCCESS]: acceptProposalSuccess,
  [ProposalTypes.ACCEPT_PROPOSAL_FAILURE]: acceptProposalFailure,

  [ProposalTypes.RESET_ERRORS]: resetErrors,
})

import { put, takeLatest } from 'redux-saga/effects'

import { API } from 'services/api'
import { AddressTypes, AddressActions } from 'store/ducks/address'
import { GET, PUT } from 'utils/constants/verbs'
import { GET_ADDRESS, UPDATE_ADDRESS } from 'utils/constants/endpoints'

export function* watchSagas() {
  yield takeLatest(AddressTypes.GET_DEFENDANT_ADDRESS, getDefendantAddress)
  yield takeLatest(AddressTypes.GET_COMPLAINANT_ADDRESS, getComplainantAddress)
  yield takeLatest(
    AddressTypes.UPDATE_DEFENDANT_ADDRESS,
    updateDefendantAddress
  )
  yield takeLatest(
    AddressTypes.UPDATE_COMPLAINANT_ADDRESS,
    updateComplainantAddress
  )
}

export function* getDefendantAddress({ payload }) {
  try {
    const { data } = yield API({
      method: GET,
      url: GET_ADDRESS(payload),
    })

    const dataSuccess = {
      result: data,
      success: true,
      errors: [],
    }

    yield put(AddressActions.getDefendantAddressSuccess(dataSuccess))
  } catch (error) {
    yield put(
      AddressActions.getDefendantAddressFailure(error.response.data)
    )
  }
}

export function* getComplainantAddress({ payload }) {
  try {
    const { data } = yield API({
      method: GET,
      url: GET_ADDRESS(payload),
    })

    const dataSuccess = {
      result: data,
      success: true,
      errors: [],
    }

    yield put(AddressActions.getComplainantAddressSuccess(dataSuccess))
  } catch (error) {
    yield put(
      AddressActions.getComplainantAddressFailure(error.response.data)
    )
  }
}

export function* updateDefendantAddress({ payload, document }) {
  try {
    const { data } = yield API({
      method: PUT,
      url: UPDATE_ADDRESS(document),
      data: payload,
    })
    yield put(AddressActions.updateDefendantAddressSuccess(data))
  } catch (error) {
    yield put(
      AddressActions.updateDefendantAddressFailure(error.response.data)
    )
  }
}

export function* updateComplainantAddress({ payload, document }) {
  try {
    const { data } = yield API({
      method: PUT,
      url: UPDATE_ADDRESS(document),
      data: payload,
    })

    const dataSuccess = {
      result: data,
      success: true,
      errors: [],
    }

    yield put(AddressActions.updateComplainantAddressSuccess(dataSuccess))
  } catch (error) {
    yield put(
      AddressActions.updateComplainantAddressFailure(error.response.data)
    )
  }
}

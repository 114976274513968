import { ACCESS_TOKEN } from 'utils/constants/constants'

const getUserId = () => {
  const oidcStorage = JSON.parse(localStorage.getItem(ACCESS_TOKEN))
  return oidcStorage?.profile.person_id
}
const getUserEmail = () => {
  const oidcStorage = JSON.parse(localStorage.getItem(ACCESS_TOKEN))
  return oidcStorage?.profile.email
}
const getUserName = () => {
  const oidcStorage = JSON.parse(localStorage.getItem(ACCESS_TOKEN))
  return oidcStorage?.profile.name
}

export { getUserId, getUserEmail, getUserName }

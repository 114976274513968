import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'
import { InputLabel, Input, FormHelperText } from '@material-ui/core'

export const CPF_SIZE_WITHOUT_MASK = 11
const CNPJ_MASK = '99.999.999/9999-99'
const CPF_MASK = '999.999.999-999999'

const CPFOrCNPJInput = (props) => {
  const {
    id, label, color, error, helperText, onChange,
  } = props

  const [mask, setMask] = useState(CPF_MASK)

  const handleChange = (event) => {
    const { value } = event.target
    const onlyNumber = value.replace(/\D/g, '')

    onChange({
      target: { id, value: onlyNumber },
    })

    if (onlyNumber.length <= CPF_SIZE_WITHOUT_MASK) {
      setMask(CPF_MASK)
    } else {
      setMask(CNPJ_MASK)
    }
  }

  const field = useMemo(() => {
    const inputProps = { ...props }
    delete inputProps.onBlur
    delete inputProps.onChange
    delete inputProps.helperText

    return <Input {...inputProps} />
  }, [props])

  return (
    <>
      <InputLabel
        htmlFor={id}
        color={color}
        error={error}
      >
        {label}
      </InputLabel>

      <InputMask
        {...props}
        mask={mask}
        maskChar={null}
        onChange={handleChange}
      >
        {() => field}
      </InputMask>

      <FormHelperText hidden={!error} error={error}>{helperText}</FormHelperText>
    </>
  )
}

CPFOrCNPJInput.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

CPFOrCNPJInput.defaultProps = {
  color: 'secondary',
  error: false,
  helperText: '',
  label: '',
  name: undefined,
  onBlur: () => { },
  onChange: () => { },
}

export default CPFOrCNPJInput

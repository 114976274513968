import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'
import { TextField } from '@material-ui/core'

const CNPJInput = (props) => {
  const { id, onChange } = props

  const handleChange = (event) => {
    const { value } = event.target
    const onlyNumber = value.replace(/\D/g, '')

    onChange({
      target: { id, value: onlyNumber },
    })
  }

  const field = useMemo(() => {
    const inputProps = { ...props }
    delete inputProps.onBlur
    delete inputProps.onChange

    return <TextField {...inputProps} />
  }, [props])

  return (
    <InputMask
      {...props}
      maskChar={null}
      onChange={handleChange}
      mask="99.999.999/9999-99"
    >
      {() => field}
    </InputMask>
  )
}

CNPJInput.propTypes = {
  id: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
}

CNPJInput.defaultProps = {
  onBlur: () => { },
  onChange: () => { },
}

export default CNPJInput

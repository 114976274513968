import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, makeStyles } from '@material-ui/core'

import { history } from 'store'
import urls from 'utils/constants/urls'
import { PeopleActions } from 'store/ducks/people'
import { ProposalActions } from 'store/ducks/proposal'
import { PolicyActions } from 'store/ducks/policy'
import {
  getProposalData,
  getPolicyData,
  getComplainant,
} from 'helpers/localStorage'
import useUtils from 'hooks/useUtils'
import Layout from 'components/core/Layout'
import Proposal from 'components/presentation/Proposal'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
    width: 920,
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      minWidth: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}))

const ProposalContainer = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { getOnlyNumber } = useUtils()

  const {
    proposal,
    isLoading,
    isSuccess,
    acceptIsLoading,
    acceptIsSuccess,
    errors,
  } = useSelector((state) => state.proposal)

  const { isLoading: policyIsLoading, errors: policyErrors } = useSelector(
    ({ policy }) => policy
  )

  const { status, quoteId, proposalId, isAccept, isComplainantWrong } =
    getProposalData()

  const { policyId } = getPolicyData()

  const { complainantDocument } = getComplainant()

  useEffect(() => {
    if (complainantDocument)
      dispatch(PeopleActions.getComplainant(getOnlyNumber(complainantDocument)))
  }, [dispatch, getOnlyNumber, complainantDocument])

  useEffect(() => {
    if (!status) history.push(urls.ROUTES.app.path)
  }, [dispatch, status])

  useEffect(() => {
    dispatch(ProposalActions.resetErrors())
  }, [dispatch])

  useEffect(() => {
    if (status === 'quote') {
      dispatch(
        ProposalActions.createProposal({
          productKey: 'garantiaRecursal',
          quoteId,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status, quoteId])

  useEffect(() => {
    if (status === 'proposal') {
      dispatch(ProposalActions.getProposal(proposalId))
    }
  }, [dispatch, status, proposalId])

  useEffect(() => {
    if (isComplainantWrong) {
      dispatch(
        ProposalActions.createProposal({
          productKey: 'garantiaRecursal',
          quoteId,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComplainantWrong])

  useEffect(() => {
    if (acceptIsSuccess) {
      dispatch(
        PolicyActions.createPolicy({
          productKey: 'garantiaRecursal',
          proposalId,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptIsSuccess])

  const createPolicy = () => {
    if (!policyId) {
      dispatch(ProposalActions.acceptProposal(proposalId))
    } else {
      history.push(urls.ROUTES.policy.path)
    }
  }

  return (
    <Layout>
      <Box className={classes.root}>
        <Proposal
          isLoading={isLoading}
          acceptIsLoading={acceptIsLoading}
          proposal={proposal}
          isSuccess={isSuccess}
          errors={errors}
          createPolicy={createPolicy}
          policyIsLoading={policyIsLoading}
          policyErrors={policyErrors}
          isAccept={isAccept}
        />
      </Box>
    </Layout>
  )
}

export default ProposalContainer

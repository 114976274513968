import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, makeStyles, TextField } from '@material-ui/core'
import { isEmpty } from 'lodash'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles(() => ({
  textField: {
    margin: 0,
  },
}))

const AutocompleteInput = ({
  id,
  onChange,
  color,
  value,
  label,
  placeholder,
  loadingText,
  noOptionsText,
  disabled,
  isLoading,
  options,
  optionLabel,
  minSize,
  searchOptions,
  ...props
}) => {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState()
  const [openProperty, setOpenProperty] = useState(false)

  useEffect(() => {
    if (!isEmpty(inputValue) && inputValue.length >= minSize) {
      setOpenProperty(true)
      searchOptions(inputValue)
    } else {
      setOpenProperty(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minSize, inputValue])

  const handleChange = (e, valueChanged) => {
    onChange({
      target: { id, value: valueChanged ?? '' }
    })
  }

  useEffect(() => {
    if (disabled) {
      setInputValue('')
    }
  }, [disabled])

  const textFieldProps = useMemo(() => {
    const inputProps = { ...props }
    delete inputProps.onBlur
    delete inputProps.onChange
    delete inputProps.id
    delete inputProps.name
    delete inputProps.value

    return inputProps
  }, [props])

  return (
    <Autocomplete
      autoComplete
      clearOnBlur
      includeInputInList
      disabled={disabled}
      value={value ? value : null}
      options={options}
      onInputChange={(e) => setInputValue(e?.target?.value)}
      getOptionLabel={optionLabel}
      onChange={handleChange}
      loading={isLoading}
      open={openProperty}
      loadingText={loadingText}
      noOptionsText={noOptionsText}
      forcePopupIcon={false}
      renderInput={(params) =>
        <TextField
          {...params}
          {...textFieldProps}
          onBlur={() => setOpenProperty(false)}
          label={label}
          margin="normal"
          placeholder={placeholder}
          color={color}
          className={classes.textField}
          value={inputValue}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      }
      {...props}
    />
  )
}

AutocompleteInput.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  color: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  loadingText: PropTypes.string,
  noOptionsText: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  options: PropTypes.array,
  optionLabel: PropTypes.func,
  minSize: PropTypes.number,
  searchOptions: PropTypes.func,
}

AutocompleteInput.defaultProps = {
  onChange: () => { },
  color: 'primary',
  label: '',
  placeholder: '',
  loadingText: 'Carregando opções...',
  noOptionsText: 'Nenhuma opção encontrada',
  disabled: false,
  isLoading: false,
  options: [],
  optionLabel: (option) => option.name,
  minSize: 0,
  searchOptions: () => { },
}

export default AutocompleteInput

import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
  }

  *:focus, 
  *:active {
    outline: 0;
  }

  :root { 
    --primary:  #F26522;
    --primary-rbg: rgba(242, 101, 34, 0.83);
    --primary-rbg-1: rgb(219 92 32);
    --primary-gradient: linear-gradient(90deg, #f26522 0, #f58c00 50%, #f26522);
    --red: #DA0707;
    --red-rbg: rgba(218, 7, 7, 0.1);
    --green: #3fc350;
    --green-rbg: rgba(0, 200, 86, 0.1);
    --white: #ffffff;
    --white-light: #F5F5F5;
    --white-rbg: rgb(243 243 243 / 80%);
    --black: #3C3C3C;
    --black-rbg: rgb(0 0 0 / 54%);
    --black-light: #666666;
    --gray: #9497A8;
    --gray-light: #AAAAAA;
    --gray-light-1: #999999;
    --gray-lighter: #FAFAFA;
    --gray-lighter-1: #E9E9EE;
    --gray-lighter-2: #EEEEEE;
    --gray-rbg: rgba(153, 153, 153, 0.5);
    --gray-rbg-1:  rgba(158, 158, 158, 0.4);
    --gray-rbg-2: rgba(204, 204, 204, 0.31);


    --font-size-default: 1rem;
    --font-family-default: 'Open Sans',  sans-serif;
    --font-poppins: 'Poppins', sans-serif;

    --sidebar-width: 41.666667%;
  }

  ::-webkit-scrollbar-track {
    border-radius: 0.25rem;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 0.25rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    background-color: var(--primary);
  }

  html {
    box-sizing: border-box;
  }

  body {
    font-family: var(--font-family-default);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: var(--font-size-default);
    background: var(--white);
    overflow-y: auto;
  }

  button {
    font: inherit;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    line-height: normal;
    appearance: none;
    color: inherit;
    border: none;
    background: transparent;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  span, a {
    transition: color .7s cubic-bezier(.5,0,0,1);
  }

  .accordion{
    &-header{
      position: relative;

      &:after{
        content: "";
        position: absolute;
        right: 0;
        padding: 0.3125rem;
        margin: 0 auto;
        border: solid var(--primary);
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
        transition-property: transform;
        transition-duration: 200ms;
        transition-timing-function: cubic-bezier(0.5, 0, 0, 1)
      }
    }

    &-wrapper {
      overflow: hidden;
      transition-property: height;
      transition-duration: time(fast);
      transition-timing-function: ease(inout);
    }

    &.active{
      .accordion-header:after{
        transform: rotate(-135deg);
      }
    }
  }

  @keyframes waves_whatsapp {
    0% {
      transform: scale(0.5);
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2);
      opacity: 0;
    }
  }
  
 
`

export default GlobalStyle

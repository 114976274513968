import { useSelector } from 'react-redux'

const useProcess = () => {
  const {
    processNumber,
    processData,
    search,
    save: { isLoading, errors },
  } = useSelector((state) => state.process)

  return {
    processNumber,
    processData,
    isLoading,
    errors,
    search,
  }
}

export default useProcess

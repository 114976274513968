import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'

import { AuthConsumer } from 'utils/auth/authProvider'
import UserArea from 'components/core/UserArea'
import Logo from 'components/core/Logo'

const useStyles = makeStyles((theme) => ({
  header: {
    padding: '0.625rem 0',
  },
  hideHeader: {
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    background: 'transparent',
  },
  noHideHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    background: 'var(--primary)',
    maxHeight: 64,
    minHeight: 64,
    boxShadow: `0px 2px 4px -1px rgba(0,0,0,0.2),
                0px 4px 5px 0px rgba(0,0,0,0.14),
                0px 1px 10px 0px rgba(0,0,0,0.12)`,
  },
  wrapper: {
    display: 'flex',
    margin: '0 auto',
    width: '100%',
    maxWidth: 1200,
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
      padding: '0 1.875rem',
      width: '100%',
    },
  },
  wrapperHideHeader: {
    justifyContent: 'flex-end',
  },
}))

const Header = ({ hideHeaderAndFooter }) => {
  const classes = useStyles()

  const userArea = () => (
    <AuthConsumer>
      {({ isAuthenticated }) =>
        isAuthenticated() ? <UserArea hideHeaderAndFooter={hideHeaderAndFooter} /> : null
      }
    </AuthConsumer>
  )

  return (
    <Box component="header" className={[classes.header, hideHeaderAndFooter ? classes.hideHeader : classes.noHideHeader]}>
      <Box className={[classes.wrapper, hideHeaderAndFooter ? classes.wrapperHideHeader : '']}>
        {!hideHeaderAndFooter && <Logo hideHeaderAndFooter={hideHeaderAndFooter} />}
        {userArea()}
      </Box>
    </Box>
  )
}

Header.propTypes = {
  hideHeaderAndFooter: PropTypes.bool,
}

Header.defaultProps = {
  hideHeaderAndFooter: false,
}

export default Header
